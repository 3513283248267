<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'project'"
      title="Projects"
      :headers="headers"
      :service="service"
      :hide-new-button="hideNewButton"
      index="project"
    >
      <!-- TYPE -->
      <template #item.type="{item}">
        <v-chip class="ma-2" label :color="projectTypeList.find(status => status.value === item.data.type).color" small>
          {{ $t('type.' + item.data.type) }}
        </v-chip>
      </template>

      <!-- STATUS -->
      <template #item.status="{item}">
        <v-chip class="ma-2" label :color="projectStatusList.find(status => status.value === item.data.status).color" small>
          {{ $t('status.' + item.data.status) }}
        </v-chip>
      </template>

      <!-- STAGE -->
      <template #item.stage="{item}">
        <v-chip class="ma-2" label :color="item.data.stage | color('stage')" small>
          {{ $t('stage.' + item.data.stage) }}
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import ProjectService from '@/services/project.service';
import Identity from '@/modules/sdk/core/identity';
import Logger from '@/modules/sdk/core/logger';
import { stageList, projectStatusList, projectTypeList } from '@/enums/global';

const d = new Logger('Admin/List/ProjectList');

@Component({
  components: {
    ListView,
  }
})
export default class ProjectList extends Vue {

  projectTypeList = projectTypeList
  projectStatusList = projectStatusList
  service = ProjectService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'ProjectForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
        operator: 'contains',
      }
    },
    {
      text: 'Type',
      align: 'start',
      sortable: true,
      value: 'type',
      filterable: {
        type: 'enum',
        items: projectTypeList,
      }
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'status',
      filterable: {
        type: 'enum',
        items: projectStatusList,
      }
    },
    {
      text: 'Stage',
      align: 'start',
      sortable: true,
      value: 'stage',
      filterable: {
        type: 'enum',
        items: stageList,
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];

  get hideNewButton(): boolean {
    return !Identity.hasRole(['dev', 'admin', 'leader']);
  }
}
</script>
