<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'users'"
      title="Users"
      :headers="headers"
      :service="service"
      index="user"
    >
      <!-- ROLE LIST -->
      <template v-slot:item.rolelist="{item}">
        <v-chip v-for="role of item.data.rolelist" :key="role.data.id" :color="role.data.index" :to="{name: 'RoleForm', params: {id: role.data.id}}" class="ma-2" label small>
          {{ $t('role.' + role.data.index) }}
        </v-chip>
      </template>

      <!-- SESSION STATE -->
      <template v-slot:item.sessionState="{item}">
        <span v-if="!item.data.loggedInAt">
          None
        </span>
        <v-chip
          v-else-if="!item.data.loggedOutAt || item.data.loggedInAt > item.data.loggedOutAt"
          color="success"
          label
          small
        >
          Logged
        </v-chip>
        <v-chip
          v-else-if="item.data.loggedOutAt && item.data.loggedInAt < item.data.loggedOutAt"
          label
          small
        >
          Logged out
        </v-chip>
      </template>

      <!-- SESSION DURATION -->
      <template v-slot:item.loggedOutAt="{item}">
        <span v-if="!item.data.loggedInAt">-</span>
        <span v-else :tick="tick" v-text="getDuration(item)" />
      </template>

    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import UserService from '@/modules/sdk/services/user.service';
import RoleService from '@/modules/sdk/services/role.service';
import UserModel from '@/modules/sdk/models/user.model';
import moment from 'moment';

let tickInterval: number;

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class UserList extends Vue {
  name = 'UserList';
  service = UserService.getInstance();
  tick = 0
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'UserForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Email',
      align: 'start',
      sortable: true,
      value: 'email',
      filterable: true
    },
    {
      text: 'First Name',
      align: 'start',
      sortable: true,
      value: 'firstName',
      filterable: true,
    },
    {
      text: 'Last Name',
      align: 'start',
      sortable: true,
      value: 'lastName',
      filterable: true,
    },
    {
      text: 'Role',
      align: 'start',
      sortable: true,
      value: 'rolelist',
      filterable: {
        field: 'RoleNode.roleId',
        type: 'distinct',
        service: RoleService,
      }
    },
    {
      text: 'Session State',
      align: 'start',
      sortable: false,
      value: 'sessionState',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Logged In', value: 'logged_in' },
          { text: 'Logged Out', value: 'logged_out' },
          { text: 'None', value: 'none' },
        ]
      }
    },
    {
      text: 'Session Duration',
      align: 'start',
      sortable: false,
      cellClass: 'text-no-wrap',
      value: 'loggedOutAt',
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];

  getDuration(user: UserModel): string {
    const endDate = user.data.loggedOutAt > user.data.loggedInAt ? user.data.loggedOutAt : new Date();
    const diff = moment(endDate).unix() - moment(user.data.loggedInAt).unix();
    const milliseconds = moment.duration(diff).as('milliseconds');
    return this.$options.filters?.duration(milliseconds);
  }

  created() {
    tickInterval = setInterval(() => this.tick++, 1000);
  }

  destroyed() {
    clearInterval(tickInterval);
  }
}
</script>
