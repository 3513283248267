<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'satisfactions'"
      title="Satisfactions"
      :headers="headers"
      :service="service"
      index="satisfaction"
      searchable
      hide-presets
    >
      <!-- RATING -->
      <template v-slot:item.rating="{ item }">
        <v-rating
          :value="item.data.rating"
          disabled
          readonly
          hover
          length="5"
          color="warning"
          background-color="warning"
        ></v-rating>
      </template>

      <!-- USER -->
      <template v-slot:item.userentity="{ item }">
        <v-chip
          :to="{name: 'UserForm', params: {id: item.data.userentity.data.id}}"
          label
          small
        >
          {{ item.data.userentity.getFullName() }}
          &lt;{{ item.data.userentity.data.email }}&gt;
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import SatisfactionService from '@/modules/sdk/services/satisfaction.service';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';

@Component({
  components: {
    ListView,
    DataTable,
  }
})
export default class SatisfactionList extends Vue {
  @Ref() readonly datatable!: DataTable

  name = 'SatisfactionList';
  service = SatisfactionService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'SatisfactionForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Rating',
      align: 'start',
      sortable: true,
      value: 'rating',
    },
    {
      text: 'User',
      align: 'start',
      sortable: true,
      value: 'userentity',
      filterable: {
        field: 'UserEntity.id',
        type: 'distinct',
        service: UserService,
        itemText: (user: UserModel) => user.getFullName()
      }
    },
    {
      text: 'Comment',
      align: 'start',
      sortable: true,
      value: 'message',
      item: {
        type: 'truncate',
        width: '200px',
      },
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
