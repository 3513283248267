var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('ListView',{directives:[{name:"test-id",rawName:"v-test-id",value:('data-extraction-presets'),expression:"'data-extraction-presets'"}],attrs:{"title":"Data Extraction Presets","headers":_vm.headers,"service":_vm.service,"options":{
      filters: [{
        field: 'template',
        operator: 'equals',
        value: 1,
      }]
    },"searchable":"","hide-presets":"","index":"data_extraction_presets"},scopedSlots:_vm._u([{key:"item.project.label",fn:function({item}){return [_vm._v(" "+_vm._s(item.data.projectentity?.data.label)+" ")]}},{key:"item.stage",fn:function({item}){return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":_vm._f("color")(item.data.stage,'stage'),"small":""}},[_vm._v(" "+_vm._s(_vm.$t('stage.' + item.data.stage))+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }