<template>
  <div>
    <portal to="toolbar_right" :order="1">
      <ShortcutDialog
        :title="$t('shortcutDialog.title')"
        :visible.sync="shortcutDialog.visible"
        :shortcuts="shortcuts"
        max-width="600"
        scrollable
      />
      <div class="d-flex align-center" style="gap: 1rem">
        <FontSize />

        <v-btn outlined text @click="onShortcutClick">
          <v-icon left>mdi-keyboard</v-icon>
          <span v-text="$t('shortcuts.btn')"></span>
        </v-btn>
      </div>
    </portal>
    <v-row v-row-resize="showSidePanel ? _rowSizes : false" v-hotkey="keymap" tabindex="1" dense>

      <!-- RECORD -->
      <v-col cols="12" :md="showSidePanel? _rowSizes[0].md : '12'" :lg="showSidePanel? _rowSizes[0].lg : '12'">
        <v-card class="fill-height">

          <v-alert v-if="!record">
            NOT FOUND
          </v-alert>

          <v-expand-transition>
            <v-alert v-if="record.data.deleted === 1" color="error" class="mb-0 pa-0" tile dark>
              <div class="w-100 d-flex align-center justify-space-between pa-4">
                <div>
                  <v-icon left>mdi-close</v-icon>
                  <span v-text="$t('recordComponent.deletedAt', {
                    date: record.data.deletedAt
                  })"></span>
                </div>
                <v-btn :disabled="restoring" :loading="restoring" @click="onRestoreRecordClick">
                  <v-icon left>mdi-delete-restore</v-icon>
                  <span v-text="$t('btn.restore')"></span>
                </v-btn>
              </div>
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert v-if="record.data.pilotUserId !== null" :color="record.data.pilotUserId === currentUserId ? 'success' : 'warning'" class="mb-0 pa-0" tile dark>
              <div class="w-100 d-flex align-center justify-space-between pa-4">
                <div v-if="record.data.pilotUserId === currentUserId">
                  <v-icon left>mdi-account-check</v-icon>
                  <span>This record is currently assigned to you.</span>
                </div>
                <div v-else>
                  <v-icon left>mdi-alert-rhombus-outline</v-icon>
                  <span>This record is currently assigned to {{ getUserFullName(record.data.pilotUserId) }}.</span>
                </div>
                <v-btn :disabled="unassigning" :loading="unassigning" @click="onUnassignRecordClick">
                  <v-icon left>mdi-close</v-icon>
                  <span>Unassign</span>
                </v-btn>
              </div>
            </v-alert>
          </v-expand-transition>

          <v-expand-transition>
            <v-alert v-if="record.data.pilotUserId === null" color="backgroundVeryLight" class="mb-0 pa-0" tile>
              <div class="w-100 d-flex align-center justify-space-between pa-4">
                <div>
                  <v-icon left>mdi-folder-alert-outline</v-icon>
                  <span>This record is currently unassigned.</span>
                </div>
                <v-btn v-if="canGetNextRecord" :disabled="assigning" :loading="assigning" @click="onAssignRecordClick">
                  <v-icon left>mdi-check</v-icon>
                  <span>Assign to me</span>
                </v-btn>
              </div>
            </v-alert>
          </v-expand-transition>

          <!-- RECORD TITLE -->
          <v-card-title>
            <h4 v-if="isAddingNewRecord">New Record</h4>
            <h4 v-else>Record #</h4>

            <v-text-field
              v-if="!isAddingNewRecord"
              v-model.number="recordNumber"
              dense
              outlined
              hide-details
              type="number"
              class="text-center ml-1"
              style="max-width: 5rem"
              @blur="() => onRecordNumberChange(recordNumber)"
              @keydown.enter="() => onRecordNumberChange(recordNumber)"
            />

            <v-spacer></v-spacer>

            <quick-status-component
              :v-model="record"
              :stage="stage"
              :user-type="userType"
              :disabled="!canSetStatus"
              ref="quickStatusComponent"
              @save-status-success="saveStatusSuccess(true)"
            ></quick-status-component>

            <v-spacer></v-spacer>

            <!-- RECORD POPOVER -->
            <div class="text-center d-flex align-center" style="gap: 1rem">

              <!-- LINKS MENU -->
              <links-menu-component
                :items="links"
                :highlighting-style.sync="highlightingStyle"
              />

              <!-- SIDE PANEL -->
              <v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="toggleSidePanel"
                  >
                    <v-icon v-if="!showSidePanel">mdi-chevron-left</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>
                  <span v-if="showSidePanel">Close sidebar</span>
                  <span v-else>Open sidebar</span>
                </span>
              </v-tooltip>
            </div>

          </v-card-title>

          <!-- RECORD CONTENT -->
          <v-card-text v-if="!editable">

            <!-- TABS -->
            <v-tabs
              v-model="tab"
            >
              <v-tab>Abstract</v-tab>
              <v-tab
                v-for="(article, articleIdx) in _articles"
                :disabled="isAddingNewRecord"
                :key="articleIdx"
              >
                <span
                  v-text="'#' + (articleIdx + 1) + ' ' + article.data.title"
                  class="text-truncate"
                  style="max-width: 10rem"
                ></span>
              </v-tab>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="isAddingNewRecord"
                    icon
                    class="mt-2 ml-3"
                    @click="onAddNewArticle"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span v-text="$t('recordComponent.addArticle')"></span>
              </v-tooltip>
            </v-tabs>
            <v-tabs-items v-model="tab" class="mt-6">
              <v-tab-item :class="['adjust-font-size', 'highlighting-style-' + highlightingStyle]">

                <!-- SOURCE TYPES -->
                <template v-if="canSeeRecordField('sourceType')">
                  <template v-if="!isEditMode">
                    <h4>Source Type</h4>
                    <p
                      v-text="(sourceTypeList.find(item => item.value === record.data.sourceType) || {}).text || 'Undefined'"
                    ></p>
                  </template>
                  <v-select
                    v-else
                    v-model="tmpRecord.data.sourceType"
                    :items="sourceTypeList"
                    label="Source type"
                    outlined
                    class="mt-1"
                  />
                </template>

                <template v-if="canSeeRecordField('authors') && tick">
                  <h4>Author</h4>
                  <p
                    v-if="!isEditMode"
                    v-html="Utils.stripHtml(record.data.authors + ' (' + record.data.year + ')')"
                    v-highlight-keywords="highlightKeywords"
                  ></p>
                  <v-alert
                    v-else
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.authors"
                      :default-model="defaultModel"
                    />
                    <v-text-field
                      v-model="tmpRecord.data.year"
                      :rules="[rules.numeric]"
                      label="Year"
                      dense
                      outlined
                      hide-details="auto"
                      class="mt-4"
                    />
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('title')">
                  <template v-if="!isEditMode">
                    <h4>Title</h4>
                    <p
                      v-html="addSpaces(Utils.stripHtml(record.data.title))"
                      v-highlight-keywords="highlightKeywords"
                    ></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.title"
                    :rules="[rules.required]"
                    label="Title"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('abstract')">
                  <template v-if="!isEditMode">
                    <h4>Abstract</h4>
                    <p
                      v-html="removeUseless(addSpaces(splitSections(Utils.stripHtml(record.data.abstract))))"
                      v-highlight-keywords="highlightKeywords"
                    ></p>
                  </template>
                  <v-textarea
                    v-else
                    v-model="tmpRecord.data.abstract"
                    label="Abstract"
                    dense
                    outlined
                  />
                </template>
                <template v-if="canSeeRecordField('affiliations')">
                  <h4>Affiliations</h4>
                  <p
                    v-if="!isEditMode"
                    v-html="Utils.stripHtml(record.data.affiliations)"
                    v-highlight-keywords="highlightKeywords"
                  ></p>
                  <v-alert
                    v-else
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.affiliations"
                      :default-model="defaultModel"
                    />
                  </v-alert>
                </template>
                <template v-if="canSeeRecordField('references')">
                  <h4>References</h4>
                  <p
                    v-if="!isEditMode"
                    v-html="Utils.stripHtml(record.data.references)"
                    v-highlight-keywords="highlightKeywords"
                  ></p>
                  <v-alert
                    v-else
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.references"
                      :default-model="defaultModel"
                    >
                      <template #item="{ item }">
                        <v-text-field
                          v-model="item.data.label"
                          :rules="[rules.url]"
                          clearable
                          outlined
                          dense
                        />
                      </template>
                    </ListBuilder>
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('doi') && record.data.doi">
                  <template v-if="!isEditMode">
                    <h4>Doi</h4>
                    <p v-html="Utils.stripHtml(record.data.doi)"></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.doi"
                    :rules="[rules.url]"
                    label="Doi"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('url')">
                  <h4 v-if="record.data.url || isEditMode">Url</h4>
                  <p
                    v-if="!isEditMode && record.data.url"
                    v-html="Utils.stripHtml(record.data.url)">
                  </p>
                  <v-alert
                    v-else-if="isEditMode"
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.url"
                      :default-model="defaultModel"
                    >
                      <template #item="{ item }">
                        <v-text-field
                          v-model="item.data.label"
                          :rules="[rules.url]"
                          clearable
                          outlined
                          dense
                        />
                      </template>
                    </ListBuilder>
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('ovid')">
                  <template v-if="!isEditMode">
                    <h4>Ovid</h4>
                    <p
                      v-html="Utils.stripHtml(record.data.ovid)"></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.ovid"
                    :rules="[rules.url]"
                    label="Ovid"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('language')">
                  <template v-if="!isEditMode">
                    <h4>Language</h4>
                    <p
                      v-html="Utils.stripHtml(record.data.language)"
                      v-highlight-keywords="highlightKeywords"
                    ></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.language"
                    label="Language"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('identifier')">
                  <template v-if="!isEditMode">
                    <h4>Identifier</h4>
                    <p
                      v-html="Utils.stripHtml(record.getIdentifier())"></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.uid"
                    label="Identifier"
                    disabled
                    dense
                    outlined
                  />
                </template>
              </v-tab-item>
              <v-tab-item
                v-for="(article, articleIdx) in _articles"
                :disabled="isAddingNewRecord"
                :key="articleIdx"
              >
                <article-component
                  v-model="_articles[articleIdx]"
                  :highlight-logic="highlightKeywords"
                  @remove="onRemoveArticle"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- RECORD SIDE PANEL -->
      <v-col cols="12" :md="showSidePanel ? _rowSizes[1].md : 12" :lg="showSidePanel ? _rowSizes[1].lg : 12">
        <component :is="showSidePanel ? 'Sticky' : 'div'" class="pb-1 mb-n1" :offset="8" :timeout="500" scrollable app>

          <v-card v-if="canGetNextRecord" class="mb-3">
            <v-card-text class="d-flex flex-wrap align-center" style="gap: 1rem">
              <v-btn
                :loading="loading"
                :disabled="loading"
                block
                outlined
                @click="onGetNextRecordClick"
              >
                Next available record
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>

          <v-card class="mb-3">
            <v-card-text class="d-flex flex-wrap align-center" style="gap: 1rem">
              <template v-if="!editing">
                <v-btn :disabled="record.data.deleted === 1" color="primary" style="flex: 1" @click="onEditRecordClick">
                  <v-icon left>mdi-pencil</v-icon>
                  <span v-text="$t('btn.edit')"></span>
                </v-btn>
                <v-btn :disabled="record.data.deleted === 1" color="error" outlined style="flex: 1" @click="onDeleteRecordClick">
                  <v-icon left>mdi-delete-outline</v-icon>
                  <span v-text="$t('btn.delete')"></span>
                </v-btn>
              </template>
              <template v-else>
                <v-btn :loading="saving" :disabled="saving" color="primary" style="flex: 1" @click="onApplyRecordChangeClick">
                  <v-icon left>mdi-content-save</v-icon>
                  <span v-text="$t('btn.save')"></span>
                </v-btn>
                <v-btn :disabled="!editing || saving" color="primary" style="flex: 1" outlined @click="onCancelRecordChangeClick">
                  <span v-text="$t('btn.cancel')"></span>
                </v-btn>
              </template>
            </v-card-text>
          </v-card>

          <!-- STATUS -->
          <status-component
            :v-model="isAddingNewRecord ? tmpRecord : record"
            :show-user-list="showUserList"
            :user-type="userType"
            :disabled="!canSetStatus"
            :stage="stage"
            ref="statusComponent"
            @save-status-success="saveStatusSuccess"
          />

          <!-- TAGS -->
          <tags-component
            v-model="(isAddingNewRecord ? tmpRecord : record).data.taglist"
            :items="tags"
            :label="$t('tagsComponent.label')"
            :disabled="isAddingNewRecord"
            :record-id="(isAddingNewRecord ? tmpRecord : record).data.id"
            :project-id="(isAddingNewRecord ? tmpRecord : record).data.projectId"
            :self-only="project.data.stage === 'screening' && !['arbitrator', 'leader'].includes(userType)"
            class="mt-3"
          />

          <!-- TAGS CATEGORIES -->
          <template v-for="(category, categoryIndex) in project.data.categorylist">
            <tags-component
              v-model="(isAddingNewRecord ? tmpRecord : record).data.taglist"
              :items="null"
              :key="'category_' + categoryIndex"
              :label="category.data.label"
              :color="category.data.color"
              :disabled="isAddingNewRecord"
              :record-id="(isAddingNewRecord ? tmpRecord : record).data.id"
              :project-id="(isAddingNewRecord ? tmpRecord : record).data.projectId"
              :category-id="category.data.id"
              :self-only="project.data.stage === 'screening' && !['arbitrator', 'leader'].includes(userType)"
              class="mt-3"
            />
          </template>

          <!-- COMMENTS -->
          <comments-component
            :disabled="isAddingNewRecord"
            :record-id="(isAddingNewRecord ? tmpRecord : record).data.id"
            :self-only="commentsAreSelfOnly"
            :user-type="userType"
            class="mt-3"
            ref="commentsComponent"
          />

          <!-- SURVEY ANSWERS BY STAGE -->
          <survey-answer-component
            :record="(isAddingNewRecord ? tmpRecord : record)"
            :project="project"
            :disabled="!canAnswerSurvey"
            :manage-answers="['arbitrator', 'leader'].includes(userType)"
            :stage="stage"
            class="mt-3"
          />
        </component>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, ModelSync, Prop, Emit, PropSync, Watch, Ref} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import RecordModel from '@/models/record.model';
import KeywordModel from '@/models/keyword.model';
import RecordService from '@/services/record.service';
import UserModel from '@/modules/sdk/models/user.model';
import Sticky from '@/modules/common/components/Sticky.vue';
import ShortcutDialog from '@/modules/common/components/ShortcutDialog.vue';
import FontSize from '@/components/FontSize.vue';
import ListBuilder from '@/modules/common/components/ListBuilder.vue';
import TagsComponent from '@/views/Admin/Component/Record/TagsComponent.vue';
import CommentsComponent from '@/views/Admin/Component/Record/CommentsComponent.vue';
import StatusComponent from '@/views/Admin/Component/Record/StatusComponent.vue';
import QuickStatusComponent from '@/views/Admin/Component/Record/QuickStatusComponent.vue';
import LinksMenuComponent from '@/views/Admin/Component/Record/LinksMenuComponent.vue';
import ArticleComponent from '@/views/Admin/Component/Record/ArticleComponent.vue';
import SurveyAnswerComponent from '@/views/Admin/Component/Record/SurveyAnswerComponent.vue';
import ProjectModel from '@/models/project.model';
import ArticleModel from '@/models/article.model';
import ArticleService from '@/services/article.service';
import Utils from '@/modules/sdk/core/utils';
import SynonymModel from '@/models/synonym.model';
import Model from '@/modules/sdk/core/model';
import { sourceTypeList } from '@/enums/global';
import Rules, { IRuleSet } from '@/modules/sdk/core/rules';
import Identity from '@/modules/sdk/core/identity';
import TagModel from '@/models/tag.model';
import { SharedQuery } from '@/utils/shared-query';

interface IShortcut {
  key: string,
  i18n: string,
  callback: () => void,
}

const d = new Logger('views/Admin/Component/RecordComponent');

@Component({
  components: {
    SurveyAnswerComponent,
    ShortcutDialog,
    LinksMenuComponent,
    ArticleComponent,
    TagsComponent,
    CommentsComponent,
    StatusComponent,
    QuickStatusComponent,
    Sticky,
    FontSize,
    ListBuilder,
  }
})
export default class RecordComponent extends Vue {
  @Ref() readonly statusComponent!: StatusComponent;
  @Ref() readonly commentsComponent!: CommentsComponent;
  @Ref() readonly quickStatusComponent!: QuickStatusComponent;
  @ModelSync('vModel', 'change', { type: RecordModel }) record!: RecordModel;
  @Prop({ default: 'none' }) userType!: string;
  @Prop({ default: () => [] }) readonly keywordList!: Array<KeywordModel>;
  @Prop({ default: () => [] }) readonly userList!: Array<UserModel>;
  @Prop({ default: () => [] }) readonly recordList!: Array<RecordModel>;
  @Prop({ default: () => {} }) readonly options!: {[key:string]: boolean | any};
  @Prop({ default: () => {} }) readonly filters!: {[key:string]: any};
  @Prop({ default: () => 'screening' }) readonly stage!: 'screening' | 'indepth' | 'final' | string;
  @Prop({ default: () => false }) showUserList!: boolean;
  @Prop({ default: null }) index!: number;
  @Prop({ type: Array, default: null }) tags?: Array<TagModel>;
  @Prop({ default: () => new ProjectModel() }) project!: ProjectModel;
  @PropSync('sidePanel', { default: () => true }) showSidePanel!: boolean;
  @PropSync('rowSizes', {
    default: () => [
      { md: 7, lg: 8 },
      { md: 5, lg: 4 },
    ]
  }) _rowSizes!: Array<any>;

  sourceTypeList = sourceTypeList
  Utils = Utils
  loading = false;
  loadingArticles = false;
  editing = false;
  editable = false;
  saving = false;
  restoring = false;
  assigning = false;
  unassigning = false;
  tab = 0;
  tick = 0;
  recordNumber = 0;
  previousRecordNumber = 0;
  shortcutDialog = {
    visible: false,
  }

  articles: Array<ArticleModel> = [];
  tmpRecord: RecordModel = new RecordModel();
  defaultModel = Model;
  rules: IRuleSet = {}
  shortcuts: Array<IShortcut> = [
    { key: 'h', i18n: 'recordComponent.shortcut.halt', callback: () => this.$emit('status', 'halt') },
    { key: 'p', i18n: 'recordComponent.shortcut.pass', callback: () => this.$emit('status', 'pass') },
    { key: 'home', i18n: 'recordComponent.shortcut.first', callback: () => this.$emit('first') },
    { key: 'end', i18n: 'recordComponent.shortcut.last', callback: () => this.$emit('last') },
    { key: 'left', i18n: 'recordComponent.shortcut.previous', callback: () => this.$emit('previous') },
    { key: 'right', i18n: 'recordComponent.shortcut.next', callback: () => this.$emit('next') },
  ];

  keymap: any = {};

  highlightingStyle = 'background';
  highlightKeywords = {
    keywords: [] as any,
    style: 'background',
    callback: undefined,
    vue: Vue,
  };

  // Links
  googleLink = 'https://scholar.google.com/scholar?q=';

  @Watch('options', {deep: true})
  @Watch('keywordList', {deep: true, immediate: true})
  onKeywordListChange() {
    Object.assign(this.highlightKeywords, {
      callback: this.onHighlightKeyword,
      keywords: [
        ...((this.options.highlightKeywords && this.keywordList.filter(keyword => !keyword.data.deleted)) || []),
        ...((this.options.highlightFilters && this.filters.map((item: any) => item.value)) || []),
      ],
      vue: this,
    });
    this.tick++;
  }

  @Watch('highlightingStyle')
  onHighlightingStyleChange(style: string) {
    localStorage.setItem('review_highlighting_style', style);
  }

  @Watch('record.data.pid', {
    immediate: true
  })
  onRecordPidChange(pid: number) {
    this.recordNumber = pid;
    this.previousRecordNumber = pid;
  }

  onShortcutClick() {
    this.shortcutDialog.visible = true;
  }

  onEditRecordClick() {
    this.editRecord(this.record);
  }

  onCreateRecordClick() {
    this.tmpRecord = new RecordModel();
    this.editing = true;
  }

  onRestoreRecordClick() {
    this.restoring = true;
    RecordService.getInstance().restore({
      id: this.record.data.id,
    })
      .then(response => {
        this.record.assign(response.data.view.single);
        this.$root.$globalSnack.success({
          message: this.$i18n.t('recordComponent.restoreRecord.snack')
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.restoring = false);
  }

  onUnassignRecordClick() {
    this.unassigning = true;
    RecordService.getInstance().update({
      id: this.record.data.id,
      pilotUserId: null,
    })
      .then(response => {
        this.record.assign(response.data.view.single);
        this.$root.$globalSnack.success({
          message: 'The record has been unassigned successfully'
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.unassigning = false);
  }

  onAssignRecordClick() {
    this.assigning = true;
    RecordService.getInstance().update({
      id: this.record.data.id,
      pilotUserId: this.currentUserId,
    })
      .then(response => {
        this.record.assign(response.data.view.single);
        this.$root.$globalSnack.success({
          message: 'The record has been assigned to you successfully'
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.assigning = false);
  }

  onDeleteRecordClick() {
    this.$root.$globalModal.ask(
      this.$i18n.t('recordComponent.removeRecordConfirm.title'),
      this.$i18n.t('recordComponent.removeRecordConfirm.body'),
      [{
        text: this.$i18n.t('btn.remove'),
        attrs: {
          outlined: true,
        },
        events: {
          click: () => {
            this.$root.$globalModal.setLoading(true);
            RecordService.getInstance().delete({
              id: this.record.data.id,
            })
              .then(response => {
                this.record.assign(response.data.view.single);
                this.$root.$globalSnack.info({
                  message: this.$i18n.t('recordComponent.removeRecordConfirm.snack')
                });
                this.$root.$globalModal.hide();
              })
              .catch(reason => this.$root.$zemit.handleError(reason))
              .finally(() => this.$root.$globalModal.setLoading(false));
          },
        }
      }, {
        text: this.$i18n.t('btn.cancel'),
        attrs: {
          text: true,
        },
        events: {
          click: () => {
            this.$root.$globalModal.hide();
          },
        }
      }],
      'danger',
    );
  }

  onCancelRecordChangeClick() {
    this.editing = false;
  }

  onApplyRecordChangeClick() {
    const record: RecordModel = this.tmpRecord.clone();
    record.data.authors = record.data.authors.map((item: any) => item.label).filter((item: string) => item).join(', ');
    record.data.affiliations = record.data.affiliations.map((item: any) => item.label).filter((item: string) => item).join(', ');
    record.data.references = record.data.references.map((item: any) => item.label).filter((item: string) => item).join(', ');
    record.data.url = record.data.url.map((item: any) => item.label).filter((item: string) => item).join(' ');

    this.saving = true;
    RecordService.getInstance().save(record)
      .then(response => {
        this.record.assign(response.data.view.single);
        this.editing = false;
        this.$root.$globalSnack.success({
          message: this.$i18n.t('recordComponent.saveRecord.snack')
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.saving = false);
  }

  onGetNextRecordClick(): Promise<RecordModel> {
    const checkNextRecord = (index: number): Promise<any> => {
      this.loading = true;
      if (this.recordList[index].data.pilotUserId === null) {
        return RecordService.getInstance().get({ id: this.recordList[index].data.id })
          .then(response => {
            const record = response.data.view.single;
            if (
              record.data.pilotUserId !== null
              // && record.data.pilotUserId !== this.currentUserId
              && this.recordList.length !== (index + 1)
            ) {
              this.recordList[index].assign(response.data.view.single);
              return checkNextRecord(index + 1);
            } else {
              this.recordList[index].assign(response.data.view.single);
              this.$emit('set-page', index + 1);
              return response;
            }
          })
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => this.loading = false);
      } else {
        return checkNextRecord(index + 1);
      }
    }

    if (this.recordList.length === (this.index + 1)) {
      return checkNextRecord(0);
    } else {
      return checkNextRecord(this.index + 1);
    }
  }

  get canGetNextRecord(): boolean {
    return ['researcher', 'secondary-researcher'].includes(this.userType || '');
  }

  get canSetStatus(): boolean {
    const user = this.userList.find(user => {
      return (
        (user.data.userType === 'researcher' && this.userType === 'researcher')
        || (user.data.userType === 'secondary-researcher' && this.userType === 'secondary-researcher')
      )
    });

    return (
      (!user || user.data.id === Identity.identity?.user.id) || !user
    ) && this.userType !== 'leader' && !this.isAddingNewRecord;
  }

  get currentUserId(): number {
    return Identity.identity?.user.id;
  }

  get isAddingNewRecord(): boolean {
    return !this.tmpRecord.data.id && this.editing;
  }

  get isEditMode(): boolean {
    return this.editing;
  }

  get canSeeSurvey() {
    return this.record.data.screeningStatus === 'pass'
      && this.record.data.indepthStatus === 'pass'
      && this.record.data.finalStatus === 'pass';
  }

  get canAnswerSurvey() {
    return !this.isAddingNewRecord && this.userType !== 'leader';
  }

  get _articles() {
    return this.isAddingNewRecord ? [] : this.articles.filter(article => !article.data.deleted);
  }

  get links() {
    // prepare pdf and doi links
    const links: Array<any> = [
      {href: this.record.data.pdf, label: 'Open PDF', icon: 'mdi-file-pdf-box'},
      {href: this.record.data.doi, label: 'Open DOI', icon: 'mdi-link-variant'},
      {href: this.record.data.ovid, label: 'Open OVID', icon: 'mdi-circle-outline'},
    ];

    // add other links
    if (this.record.data.url) {
      this.record.data.url.split(' ').forEach((item: string, key: number) => {
        links.push({href: item, label: 'Open URL (' + (key + 1) + ')', icon: 'mdi-link'})
      });
    }

    // add google link
    links.push({href: this.googleLink + encodeURIComponent(this.record.data.title), label: 'Google Scholar', icon: 'mdi-google'});

    // add articles
    this._articles.filter(article => article.data.fileentity.data.id).forEach(article => {
      const file = article.data.fileentity;
      const path = [
        file.data.category,
        file.data.id,
        file.data.path,
      ];
      links.push({ href: '/file/download/' + path.join('/'), label: article.data.fileentity.data.name, icon: 'mdi-file-pdf-box'});
    })

    return links;
  }

  get commentsAreSelfOnly(): boolean {
    return this.project.data.stage === 'screening'
      || Identity.hasRole(['dev', 'admin'])
      || ['arbitrator', 'leader'].includes(this.userType || '')
  }

  get canCreateRecord(): boolean {
    return !this.editing && this.stage !== 'screening' && this.record.data.deleted !== 1;
  }

  getUserFullName(id: number) {
    return this.project.getUserById(id)?.getFullName();
  }

  onRecordNumberChange(pid: number): void {
    const index = this.recordList.findIndex(record => record.data.pid === pid);
    if (index !== -1) {
      this.previousRecordNumber = pid;
      this.$emit('pid-change', pid);
    } else {
      this.recordNumber = this.previousRecordNumber;
    }
  }

  onAddNewArticle(): void {
    this.articles.push(new ArticleModel({
      projectId: this.record.data.projectId,
      recordId: this.record.data.id,
    }));
    setTimeout(() => { // Required for tab animation
      this.tab = this.articles.length;
    }, 100);
  }

  onRemoveArticle(article: ArticleModel): void {
    const index = this.articles.findIndex(item => item === article);
    this.articles.splice(index, 1);
    if (this.tab > this.articles.length) {
      this.tab = this.articles.length;
    }
  }

  onHighlightKeyword(element: HTMLElement, word: KeywordModel | SynonymModel | string) {
    switch (this.highlightingStyle) {
      case 'background':
        element.style.fontWeight = '';
        if (word instanceof KeywordModel) {
          element.style.backgroundColor = word.data.color;
        } else if (word instanceof SynonymModel) {
          element.classList.add('grey--text');
          element.classList.add(word.getTextClass());
          element.style.backgroundColor = word.data.color;
        }
        break;
      case 'text':
        element.style.fontWeight = 'bold';
        if (word instanceof KeywordModel) {
          element.style.color = word.data.color;
        } else if (word instanceof SynonymModel) {
          element.style.color = word.data.color;
        }
        break;
    }
  }

  canSeeRecordField(key: string): boolean {
    return this.options.recordFields.length === 0 || this.options.recordFields.indexOf(key) !== -1;
  }

  setStatus(status: string) {
    this.quickStatusComponent.saveStatus({
      status,
      color: 'success',
      loading: true,
    })
  }

  toggleSidePanel() {
    this.showSidePanel = !this.showSidePanel;
  }

  editRecord(record: RecordModel) {
    this.tmpRecord = record.clone();
    this.tmpRecord.data.authors = (this.tmpRecord.data.authors || '').split(',').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.data.affiliations = (this.tmpRecord.data.affiliations || '').split(',').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.data.references = (this.tmpRecord.data.references || '').split(',').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.data.url = (this.tmpRecord.data.url || '').split(' ').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.editing = true;
  }

  save(record: RecordModel) {
    this.loading = true;
    RecordService.getInstance().saveUserStatus(record, {
      params: {
        userType: this.userType,
      }
    })
      .then((response) => {
        this.record.assign(response.data.view.single);
      })
      .finally(() => this.loading = false);
  }

  loadArticles(record: RecordModel): Promise<ArticleModel> {
    this.loadingArticles = true;
    return ArticleService.getInstance().getAll({
      filters: [{
        field: 'recordId',
        value: record.data.id,
        operator: 'equals'
      }],
      order: 'id desc'
    })
      .then((response) => this.articles = response.data.view.list)
      .finally(() => this.loadingArticles = false);
  }

  splitSections(str?: string) {
    if (!this.options.splitSection) {
      return str;
    }
    if (typeof str !== 'string') {
      return str;
    }

    str = str.replace(/(^|[\.|\;|\!|\?][\s|])([A-Z]\S+[\s]?([^\r\n\t\f\v :;.\?\!]+[\s]){0,4}(\S+|[0-9]+):)/gm, function(
      match: string,
      p1: string,
      p2: string,
      p3: string,
      offset: number,
      string: string
    ) {
      return p1 + '<h4 class="font-weight-bold d-block pt-1">' + p2 + '</h4>';
    });

    return str;
  }

  addSpaces(str?: string) {
    if (!this.options.addSpaces) {
      return str;
    }
    if (typeof str !== 'string') {
      return str;
    }

    str = str.replace(/(?=\S)(\;)(?=\S)/gm, function(
      match: string,
      p1: string,
      offset: number,
      string: string
    ) {
      return p1 + ' ';
    });

    return str;
  }

  removeUseless(str?: string) {
    if (!this.options.removeUseless) {
      return str;
    }
    if (typeof str !== 'string') {
      return str;
    }

    str = str.replace(/([\s]{0,1}copyright[\s]{0,1}©[\s]{0,1}[0-9]+)/gmi, function(
      match: string,
      p1: string,
      offset: number,
      string: string
    ) {
      return '';
    });

    return str;
  }

  @Emit()
  saveStatusSuccess(nextPage = false) {
    this.loadProjectProgress();

    if (nextPage) {
      this.nextPage();
    }
  }

  @Emit()
  loadProjectProgress() {
  }

  @Emit()
  nextPage() {
  }

  reload(): Promise<RecordModel> {
    this.loading = true;
    return RecordService.getInstance().get({
      id: this.record.data.id,
    })
      .then((response) => {
        this.record.assign(response.data.view.single);
        this.loadRecordStatus();
        this.loadComments();
        return this.record;
      })
      .finally(() => this.loading = false);
  }

  loadRecordStatus() {
    this.statusComponent.loadRecordStatus();
  }

  loadComments() {
    this.commentsComponent.loadCommentList();
  }

  loadHighlightingStyle(): void {
    const style = localStorage.getItem('review_highlighting_style');
    if (style) {
      this.highlightingStyle = style;
    }
  }

  created(): void {
    this.loadHighlightingStyle();
    this.loadArticles(this.record);
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required').toString(),
      url: (value: string) => Rules.isUrl(value) || this.$t('rules.url').toString(),
      numeric: (value: string|number) => Rules.digit(value) || this.$t('rules.digit').toString(),
    };

    this.shortcuts.forEach((shortcut: any) => {
      this.keymap[shortcut.key] = () => shortcut.callback();
    });
  }
}
</script>
