<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'keyword'"
      v-bind="$attrs"
      :headers="headers"
      :service="service"
      :query="query"
      :new-route="newRoute"
      title="Keywords"
      index="keyword"
    >
      <!-- PROJECT -->
      <template v-slot:item.projectId="{item}">
        <span class="text-no-wrap">
          {{item.data.projectentity.data.label}}
        </span>
      </template>

      <!-- LABEL -->
      <template v-slot:item.label="{item}">
        <KeywordChip
          :value="item"
          small
          filled
        />
      </template>

      <!-- SYNONYMS -->
      <template v-slot:item.synonymlist="{ item }">
        <span v-if="item.data.synonymlist.length === 0"></span>
        <div class="d-flex align-center" style="gap: 0.5rem">
          <template v-for="synonym of item.data.synonymlist">
            <KeywordChip
              v-if="!synonym.data.deleted"
              :value="synonym"
              :key="synonym.data.id"
              small
              filled
            />
          </template>
        </div>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop} from 'vue-property-decorator';
import KeywordChip from '@/components/KeywordChip.vue';
import ListView from '@/modules/common/components/ListView.vue';
import KeywordService from '@/services/keyword.service';
import ProjectService from '@/services/project.service';
import SynonymService from '@/services/synonym.service';
import { Route } from 'vue-router';

@Component({
  components: {
    ListView,
    KeywordChip,
  }
})
export default class KeywordList extends Vue {
  @Prop({ type: [Number, String] }) readonly projectId!: number;
  service = KeywordService.getInstance();
  newRoute: Partial<Route> = {name: 'KeywordNew'};

  get query(): Array<object> {
    const query = [];
    if (this.projectId) {
      query.push({
        field: 'projectId',
        operator: 'equals',
        value: this.projectId,
      })
    }
    return query;
  }

  get headers(): Array<any> {
    return [
      {
        text: '',
        align: 'start',
        sortable: false,
        fixed: true,
        width: 72,
        value: '_first',
        item: {
          type: 'button',
          attrs: {},
          icon: {
            text: 'mdi-pencil-outline'
          },
          to: {
            name: this.projectId ? 'ProjectKeywordForm' : 'KeywordForm',
            params: this.projectId ? {
              projectId: 'props.item.data.projectId',
              id: 'props.item.data.id',
            } : {
              id: 'props.item.data.id',
            }
          }
        }
      },
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        fixed: true,
        value: 'id',
        filterable: {
          type: 'number',
        },
      },
      {
        text: 'Project',
        align: 'start',
        sortable: true,
        value: 'projectId',
        filterable: {
          type: 'distinct',
          service: ProjectService,
        }
      },
      {
        text: 'Category',
        align: 'start',
        sortable: true,
        value: 'label',
        filterable: {
          type: 'text',
        }
      },
      {
        text: 'Keywords',
        align: 'start',
        value: 'synonymlist',
        filterable: {
          field: 'Synonym.label',
          type: 'text',
          service: SynonymService,
        }
      },
      {
        text: 'Created at',
        align: 'start',
        sortable: true,
        value: 'createdAt',
        filterable: {
          type: 'date'
        },
      },
      {
        text: 'Updated at',
        align: 'start',
        sortable: true,
        value: 'updatedAt',
        filterable: {
          type: 'date'
        },
      },
      {
        text: 'Deleted',
        align: 'start',
        sortable: true,
        value: 'deleted',
        filterable: {
          type: 'boolean'
        },
      },
      {
        text: '',
        align: 'start',
        sortable: false,
        value: '_last',
      },
    ];
  }

  created() {
    if (this.projectId) {
      this.newRoute = { name: 'ProjectKeywordNew', params: {projectId: this.projectId.toString()} };
    }
  }
}
</script>
