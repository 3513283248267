<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'contacts'"
      title="Contacts"
      :headers="headers"
      :service="service"
      index="contact"
      searchable
      hide-presets
    >
      <!-- TYPE -->
      <template v-slot:item.type="{ item }">
        <v-chip
          class="text-capitalize"
          label
          small
        >
          {{item.data.type}}
        </v-chip>
      </template>

      <!-- URGENT -->
      <template v-slot:item.urgent="{ item }">
        <v-switch
          v-model="item.data.urgent"
          :label="item.data.urgent? 'Yes' : 'No'"
          inset
          color="error"
          readonly
        />
      </template>

      <!-- MESSAGE -->
      <template v-slot:item.message="{ item }">

      </template>

      <!-- USER -->
      <template v-slot:item.userentity="{ item }">
        <v-chip
          :to="{name: 'UserForm', params: {id: item.data.userentity.data.id}}"
          label
          small
        >
          {{ item.data.userentity.getFullName() }}
          <{{ item.data.userentity.data.email }}>
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import ContactService from '@/modules/sdk/services/contact.service';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';
import {contactTypeList} from "@/enums/global";

@Component({
  components: {
    ListView,
    DataTable,
  }
})
export default class ContactList extends Vue {
  @Ref() readonly datatable!: DataTable

  name = 'ContactList';
  service = ContactService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'ContactForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Type',
      align: 'start',
      sortable: true,
      value: 'type',
      filterable: {
        type: 'enum',
        items: contactTypeList,
      }
    },
    {
      text: 'Urgent',
      align: 'start',
      sortable: true,
      value: 'urgent',
      filterable: {
        type: 'boolean',
      }
    },
    {
      text: 'User',
      align: 'start',
      value: 'userentity',
      filterable: {
        field: 'UserEntity.id',
        type: 'distinct',
        service: UserService,
        itemText: (user: UserModel) => user.getFullName()
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
