<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'categories'"
      v-bind="$attrs"
      :headers="headers"
      :service="service"
      :query="query"
      :new-route="newRoute"
      title="Categories"
      index="category"
    >
      <!-- CATEGORY LABEL -->
      <template v-slot:item.label="{ item }">
        <v-chip
          v-text="item.data.label"
          :color="item.data.color"
          :text-color="item.getTextColor(true)"
          :to="{name: 'CategoryForm', params: {id: item.data.id}}"
          label
          chips
          link
          small
        >
        </v-chip>
      </template>

      <!-- PROJECTS -->
      <template #item.projectlist="{ item }">
        <span v-if="getAvailableProjects(item).length === 0">All projects</span>
        <v-chip
          v-for="project of getAvailableProjects(item)"
          v-text="project.getLabel()"
          :key="project.data.id"
          :to="{name: 'ProjectForm', params: {id: project.data.id}}"
          label
          chips
          class="ma-1"
          link
          small
        >
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import DataTable from '@/modules/common/components/DataTable.vue';
import CategoryService from '@/services/category.service';
import ProjectModel from '@/models/project.model';
import NotificationModel from '@/modules/sdk/models/notification.model';
import ProjectService from '@/services/project.service';
import { Route } from 'vue-router';

@Component({
  components: {
    ListView,
  }
})
export default class CategoryList extends Vue {
  @Ref() readonly datatable!: DataTable

  @Prop({ type: [Number, String] }) readonly projectId!: number;

  name = 'CategoryList';
  service = CategoryService.getInstance();
  newRoute: Partial<Route> = {name: 'CategoryNew'};

  get headers(): Array<object> {
    const headers: any = [{
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'CategoryForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    }, {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    }, {
      text: 'Category',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: {
        type: 'text',
      }
    }];

    if (!this.projectId) {
      headers.push({
        text: 'Projects',
        align: 'start',
        value: 'projectlist',
        sortable: false,
        filterable: {
          field: 'recordnode.projectId',
          type: 'distinct',
          service: ProjectService,
        }
      });
    }

    headers.push(...[{
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    }, {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    }, {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    }]);

    return headers;
  }

  get query(): Array<object> {
    const query = [];
    if (this.projectId) {
      query.push({
        field: 'projectnode.projectId',
        operator: 'equals',
        value: this.projectId,
      })
    }
    return query;
  }

  getAvailableProjects(item: NotificationModel): Array<any> {
    return item.data.projectlist.filter((project: ProjectModel) => !project.data.deleted);
  }

  created() {
    if (this.projectId) {
      this.newRoute = { name: 'ProjectCategoryNew', params: {
        projectId: this.projectId.toString()
      } };
    }
  }
}
</script>
