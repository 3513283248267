<template>
  <div id="mode_cue" class="d-none px-2 py-1 caption text-no-wrap" v-text="mode.text"></div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator';

const lastClient: any = {}

@Component
export default class ModeCue extends Vue {
  @VModel() mode!: any
  @Prop({default: () => ({ x: 0, y: 0 })}) offset!: { x: number, y: number }

  @Watch('mode', {
    deep: true,
    immediate: true,
  })
  onModeChange(mode: any) {
    if (this.element) {
      this.element.textContent = mode.text;
    }
  }

  element: HTMLDivElement | null = null

  onMouseMove(event: any) {
    if (this.element) {
      this.element.style.top = ((event.clientY || lastClient.y) + window.scrollY + this.offset.y) + 'px';
      this.element.style.left = ((event.clientX || lastClient.x) + window.scrollX + this.offset.x) + 'px';

      if (event.clientY) {
        lastClient.y = event.clientY;
        lastClient.x = event.clientX;
      }
    }
  }

  mounted() {
    const element = document.getElementById('mode_cue');
    if (element) {
      this.element = element.cloneNode(true) as HTMLDivElement;
      this.element.classList.remove('d-none');
      const app = document.getElementById('app');
      if (app) {
        app.appendChild(this.element);
      }
    }
  }

  created() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('scroll', this.onMouseMove);
  }

  destroyed() {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('scroll', this.onMouseMove);
    if (this.element) {
      this.element.remove();
    }
  }
}
</script>

<style lang="scss" scoped>
#mode_cue {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  border-radius: 3px;
}
.theme--dark {
  #mode_cue {
    background-color: white;
    color: black;
  }
}
.theme--light {
  #mode_cue {
    background-color: black;
    color: white;
  }
}
</style>
