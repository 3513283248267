<template>
  <div>
    <query-builder
      v-model="conditionList"
      :fields="fields"
    />
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import ProjectStatusReasonModel from '@/models/project-status-reason.model'
import ProjectStatusReasonService from '@/services/project-status-reason.service'
import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator';
import {
  extraFieldList,
  fieldList,
  officialStatusFieldList,
  statusReasonFieldList,
  sourceTypeList,
  statusList
} from '@/enums/global'
import RecordService from '@/services/record.service';
import ProjectModel from '@/models/project.model';
import KeywordModel from '@/models/keyword.model';
import TagModel from '@/models/tag.model';
import SurveyGroupModel from '@/models/survey-group.model';
import SurveyModel from '@/models/survey.model';
import SurveyQuestionModel from '@/models/survey-question.model';
import QueryBuilder from '@/modules/common/components/QueryBuilder.vue';
import ProjectUserModel from '@/models/project-user.model';
import CategoryModel from '@/models/category.model'
import RecordTagModel from '@/models/record-tag.model'
import Logger from '@/modules/sdk/core/logger';
import UserModel from '@/modules/sdk/models/user.model';
import UserService from '@/modules/sdk/services/user.service';
import { IQueryAdvanced } from '@/modules/sdk/core/interfaces';
import { SharedQuery } from '@/utils/shared-query';

const d = new Logger('component/ProjectQueryBuilder.vue');

@Component({
  components: {
    QueryBuilder,
  }
})
export default class ProjectQueryBuilder extends Vue {

  @VModel({ type: Array, default: () => [] }) conditionList!: any;
  @Prop({ type: Number, default: null }) projectId!: number;
  @Prop({ type: Object, default: null }) mode!: any;
  @Prop({ type: ProjectModel, default: null }) project?: ProjectModel;
  @Prop({ type: Array, default: null }) keywords?: Array<KeywordModel>;
  @Prop({ type: Array, default: null }) tags?: Array<TagModel>;
  @Prop({ type: Object, default: () => ({
      stage: null,
      status: null,
      userId: null,
      userStatus: null,
      reviewed: null,
      order: [],
      orderAsc: []
  }) }) advanced!: IQueryAdvanced;

  loading = false;
  innerProject: ProjectModel | null = null
  innerKeywords: Array<KeywordModel> = []
  innerTags: Array<TagModel> = []
  projectUserList: Array<UserModel> = [];
  projectStatusReasonList: Array<ProjectStatusReasonModel> = [];
  languageList: Array<string> = [];

  @Watch('projectId')
  onProjectIdChange(id: number) {
    this.clear();
    this.load();
  }

  @Watch('project', { deep: true })
  onProjectChanged(project: ProjectModel) {
    if (project) {
      this.innerProject = project;
    }
  }

  @Watch('keywords', { deep: true })
  onKeywordsChanged(keywords: Array<KeywordModel>) {
    if (keywords) {
      this.innerKeywords = keywords;
    }
  }

  @Watch('tags', { deep: true })
  onTagsChanged(tags: Array<TagModel>) {
    if (tags) {
      this.innerTags = tags;
    }
  }

  get fields(): Array<any> {
    let fields: Array<any> = [{
      header: this.$i18n.t('builderComponent.fields'),
    }, ...fieldList, {
      header: this.$i18n.t('builderComponent.officialStatuses'),
    }, ...officialStatusFieldList, {
      header: this.$i18n.t('builderComponent.statusReasons'),
    }, ...statusReasonFieldList, {
      header: this.$i18n.t('builderComponent.extraFields'),
    }, ...extraFieldList];

    if (this.innerProject) {

      if (this.advanced.stage && this.mode && ['leader', 'arbitrator'].includes(this.mode.value)) {
        fields.push({
          header: 'User Status',
        });
        this.projectUserList.forEach((user: UserModel) => {
          fields.push({
            text: user.getFullName(),
            value: 'User.' + user.data.id,
          })
        });
      }

      // tags categories
      if (this.innerProject.data.categorylist) {
        fields.push({
          header: 'Eligibility Tags',
        });
        this.innerProject.data.categorylist.forEach((category: CategoryModel) => {
          fields.push({
            text: category.data.label,
            value: 'Category.' + category.data.id + '.Tag.' + 'label'
          })
        });
      }

      // survey groups and questions
      if (this.innerProject.data.surveylist) {
        this.innerProject.data.surveylist.forEach((survey: SurveyModel) => {
          survey.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
            if (group.data.surveyquestionlist.length > 0) {
              fields.push({
                header: this.$i18n.t('builderComponent.questions') + ' > ' + group.data.label,
              });
              group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
                fields.push({
                  text: question.data.label,
                  value: 'SurveyQuestion.' + question.data.id + '.SurveyAnswer.content'
                });
              })
            }
          })
        });
      }
    }

    fields = fields.map(field => ({
      ...field,
      ...this.getValueAttrs(field.value),
    }))

    return fields;
  }

  filterTagsCategory(category: number | null = null) {
    return (tag: TagModel) => {
      return tag.data.recordnode && tag.data.recordnode.findIndex((recordNode: RecordTagModel) => {
        return recordNode.data.categoryId === category;
      }) !== -1;
    }
  }

  mapTagModelToItem(tag: TagModel) {
    return {
      text: tag.data.label,
      value: tag.data.label,
      color: tag.data.color,
      tag,
    };
  }

  getValueAttrs(field: string): any {
    let items: Array<any>;
    switch (field) {
      case 'pilotUserId':
        items = (this.project || new ProjectModel()).data.usernode
          .filter((model: ProjectUserModel) => ['researcher', 'secondary-researcher'].includes(model.data.type))
          .map((model: ProjectUserModel) => ({
            text: model.data.userentity.getFullName(),
            value: model.data.userId,
            model: model.data.userentity
          }));
        break;
      case 'Tag.label':
        items = this.innerTags
          .filter(this.filterTagsCategory(null))
          .map(model => this.mapTagModelToItem(model));
        break;
      case 'screeningStatus':
      case 'indepthStatus':
      case 'finalStatus':
        items = statusList;
        break;
      case 'screeningStatusReason':
      case 'indepthStatusReason':
      case 'finalStatusReason':
        items = this.projectStatusReasonList
          .filter((model: ProjectStatusReasonModel) => model.data.stage === field.replace('StatusReason', ''))
          .map((model: ProjectStatusReasonModel) => ({
            text: model.data.label,
            value: model.data.id,
            model: model
          }));
        break;
      case 'sourceType':
        items = sourceTypeList;
        break;
      case 'language':
        items = this.languageList;
        break;
      case 'title':
      case 'abstract':
      case 'Article.title':
      case 'Article.content':
        items = this.innerKeywords.map(model => ({
          text: model.data.label,
          value: model.data.id,
          color: model.data.color,
          model,
        }));
        break;
      default:
        items = [];
        break;
    }

    if (field && field.startsWith('User.')) {
      items = statusList.filter(item => item.value !== 'conflict');
    }

    if (field && field.startsWith('Category.')) {
      const categoryPath = field.split('.');
      items = this.innerTags
        .filter(this.filterTagsCategory(parseInt(categoryPath[1])))
        .map(this.mapTagModelToItem)
    }

    const type = ['id', 'pid', 'year'].includes(field)
      ? 'numeric'
      : Array.isArray(items)
          ? 'list'
          : null;

    return {
      items,
      type,
      itemColor: 'color',
    };
  }

  clear() {
    // this.innerProject = null;
    // this.innerKeywords = [];
    this.conditionList.splice(0, this.conditionList.length, {
      logic: 'and',
      operator: 'contains',
      field: null,
      value: null,
      group: [] as any,
    });
  }

  load() {
    if (this.projectId) {
      this.loading = true;
      Promise.all([
        this.project || SharedQuery.getProject(this.projectId),
        this.keywords || SharedQuery.getProjectKeywords(this.projectId),
        this.tags || SharedQuery.getProjectTagsByCategoryId(this.projectId),
        RecordService.getInstance().getAll({
          filters: [{
            field: 'projectId',
            value: this.projectId,
            operator: 'equals'
          }],
          group: 'language', // Distinct
        }),
        ProjectStatusReasonService.getInstance().getAll({
          filters: [[
            {
              field: 'projectId',
              value: this.projectId,
              operator: 'equals'
            },
            {
              field: 'projectId',
              operator: 'is null'
            }
          ]],
        }),
        UserService.getInstance().getAll({ order: 'firstName, lastName, email', advanced: { activeUserProjectId: this.projectId } }),
      ])
        .then(([project, keywords, tags, responseRecord, projectStatusReason, responseUserList]) => {
          this.innerProject = this.project || project;
          this.innerKeywords = this.keywords || keywords;
          this.innerTags = this.tags || tags;
          this.projectUserList = responseUserList.data.view.list;
          this.projectStatusReasonList = projectStatusReason.data.view.list;

          const languages: Array<string> = [];
          responseRecord.data.view.list.forEach((item: any) => {
            const newItems = [];
            if (item.data.language.indexOf(',') !== -1) {
              newItems.push(...item.data.language.split(',').map((item: string) => item.trim()));
            } else {
              newItems.push(item.data.language);
            }
            newItems.forEach((newItem: string) => {
              if (!languages.includes(newItem)) {
                languages.push(newItem);
              }
            });
          })
          this.languageList = languages.sort();
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.loading = false);
    }
  }

  created() {
    this.load();
  }
}
</script>
