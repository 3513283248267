var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{directives:[{name:"test-id",rawName:"v-test-id",value:('app'),expression:"'app'"}]},[_c('v-navigation-drawer',{directives:[{name:"test-id",rawName:"v-test-id",value:('app-navigation'),expression:"'app-navigation'"}],attrs:{"mini-variant":_vm._mini,"permanent":"","dark":"","app":""},on:{"update:miniVariant":function($event){_vm._mini=$event},"update:mini-variant":function($event){_vm._mini=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.mini)?_c('v-list-item',{staticClass:"px-2"},[_c('v-btn',{directives:[{name:"test-id",rawName:"v-test-id",value:('app-mini-toggle-button2'),expression:"'app-mini-toggle-button2'"}],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[(!_vm.mini)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-btn',{attrs:{"icon":"","to":{name: 'UserForm', params: {id: _vm.currentUserId}}}},[_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1)],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('v-btn',{directives:[{name:"test-id",rawName:"v-test-id",value:('app-mini-toggle-button'),expression:"'app-mini-toggle-button'"}],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[(!_vm.mini)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider')]},proxy:true},{key:"append",fn:function(){return [_c('v-slide-y-reverse-transition',[(!_vm._mini)?_c('v-sheet',{staticClass:"caption text-right text-no-wrap",attrs:{"dark":""}},[_c('div',{staticClass:"px-4 py-1",staticStyle:{"opacity":"0.5"},domProps:{"textContent":_vm._s(_vm.$t('footer.version', {
            version: _vm.version,
          }))}})]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuList),function(group,groupIdx){return _c('div',{key:groupIdx},[(group.children && group.label)?_c('v-subheader',{staticClass:"d-block text-truncate pt-3",domProps:{"textContent":_vm._s(group.label)}}):_vm._e(),_vm._l((group.children),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":item.to,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)})],2)}),0)],1),_c('v-app-bar',{staticStyle:{"z-index":"100"},attrs:{"id":"toolbar","app":""}},[_c('div',{staticClass:"d-flex align-center ml-1",staticStyle:{"gap":"1rem"}},[_c('v-btn',{attrs:{"to":{name: 'HomeView'},"active-class":"v-btn-hide-active","exact":"","text":"","x-large":""}},[_c('img',{style:((_vm.$vuetify.theme.dark ? 'filter: invert(1);' : '')
            + 'height: 3rem;'),attrs:{"src":require('@/assets/images/logo.svg'),"alt":"Logo"}})]),(!['prod'].includes(_vm.env.key))?_c('v-chip',{attrs:{"color":_vm.env.color}},[_c('v-avatar',{staticClass:"flashing",attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.env.icon))])],1),_vm._v(" "+_vm._s(_vm.env.title)+" ")],1):_vm._e()],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"gap":"0.5rem","flex":"1"}},[_c('portal-target',{staticClass:"d-flex align-center mr-3",staticStyle:{"gap":"1rem"},attrs:{"name":"toolbar_right","multiple":""}}),_c('theme-toggle'),_c('notification-menu'),_c('InlineTutorial',{attrs:{"slug":"help"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('MenuTooltip',_vm._g({attrs:{"btn-attrs":{
              icon: true,
            },"icon":"mdi-help-circle-outline","tooltip":"Help"}},on))]}}])}),_c('SatisfactionSurveyModal',{attrs:{"visible":_vm.showSurvey},on:{"update:visible":function($event){_vm.showSurvey=$event}}}),_c('MenuTooltip',{attrs:{"btn-attrs":{
          icon: true,
        },"icon":"mdi-check-decagram","tooltip":"Satisfaction survey"},on:{"click":() => _vm.showSurvey = true}}),_c('LogoutModal',{attrs:{"visible":_vm.showLogout,"dark":""},on:{"update:visible":function($event){_vm.showLogout=$event}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"test-id",rawName:"v-test-id",value:('app-logout-button'),expression:"'app-logout-button'"}],attrs:{"icon":"","text":""},on:{"click":() => _vm.showLogout = true}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}])},[_c('span',{directives:[{name:"test-id",rawName:"v-test-id",value:('app-logout-tooltip'),expression:"'app-logout-tooltip'"}]},[_vm._v(" Logout ")])])],1)],1),_c('v-main',[_c('v-container',{staticClass:"background fill-height align-start",attrs:{"fluid":""}},[_c('router-view',{key:_vm.$route.fullPath,staticClass:"w-100"})],1)],1),_c('v-footer',{staticStyle:{"border-top":"rgba(0,0,0,.1) solid 1px"},attrs:{"padless":"","fixed":"","app":"","inset":""}},[_c('div',{staticClass:"caption pa-1 text-center w-100"},[_vm._v(" "+_vm._s('Copyright © 2023-' + new Date().getFullYear())+" YolaRx Consultant Inc. — "),_c('strong',[_vm._v("All rights reserved.")])])]),_c('global-confetti'),_c('global-overlay'),_c('global-snack',{attrs:{"top":"","app":""}}),_c('global-modal',{attrs:{"scrollable":""}}),_c('global-prompt'),_c('should-take-action')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }