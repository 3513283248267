<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between">
      <span v-text="$t('commentsComponent.title', {
        total: recordId ? commentList.length : 0
      })"></span>
      <v-btn
        :disabled="!canRefresh"
        :loading="loading"
        outlined
        color="primary"
        small
        @click="onRefreshClick"
      >
        <span v-text="$t('btn.refresh')"></span>
      </v-btn>
    </v-card-title>
    <v-card-text class="pl-0 pb-0" style="max-height: 600px; overflow: auto;">
      <v-list>
        <v-timeline
          dense
          clipped
          class="pt-0"
        >
          <v-timeline-item
            fill-dot
            class="white--text d-flex align-center"
            color="grey"
            large
            small
          >
            <template #icon>
              <span>{{ user.getInitials() }}</span>
            </template>

            <v-form v-model="valid" ref="form">
              <!-- POST COMMENT FIELD -->
              <v-text-field
                v-model="content"
                :disabled="!canPost"
                :loading="saving"
                :error-messages="errors.content"
                :rules="[rules.required]"
                hide-details="auto"
                :label="'Leave a ' + (private? 'private' : 'public') + ' comment...'"
                outlined
                flat
                solo
                clearable
                @blur="form.resetValidation()"
                @keydown.enter="onPostComment"
                @input="errors = {}"
              >
                <!-- PRIVATE TOGGLE -->
                <template v-slot:prepend-inner>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="mx-0"
                        depressed
                        icon
                        :disabled="disabled"
                        :color="private? 'error' : 'success'"
                        @click="() => togglePrivate()"
                      >
                        <v-icon v-if="private">mdi-eye-off-outline</v-icon>
                        <v-icon v-else>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-if="private">{{$t('tooltips.comment.private')}}</template>
                    <template v-else>{{$t('tooltips.comment.public')}}</template>
                  </v-tooltip>
                </template>

                <!-- POST COMMENT -->
                <template v-slot:append>
                  <v-btn
                    class="mx-0"
                    depressed
                    icon
                    :disabled="!canPost"
                    :loading="saving"
                    @click="onPostComment"
                  >
                    <v-icon>mdi-send-outline</v-icon>
                  </v-btn>
                </template>

              </v-text-field>
              <!-- END POST COMMENT FIELD -->
            </v-form>

          </v-timeline-item>

          <template v-if="recordId">
            <v-timeline-item
              v-for="(comment, commentIdx) in filteredComments"
              :key="comment.data.id"
              class="mb-3 pb-3"
              color="white"
              small
            >
              <template v-slot:icon>
                <template v-if="comment.canEdit()">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :loading="comment.loading"
                        :color="comment.isPrivate()? 'error' : 'success'"
                        :disabled="disabled"
                        depressed
                        icon
                        class="mt-1"
                        @click="() => togglePrivate(comment)"
                      >
                        <v-icon v-if="comment.isPrivate()">mdi-eye-off-outline</v-icon>
                        <v-icon v-else>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-if="comment.isPrivate()">{{$t('tooltips.comment.private')}}</template>
                    <template v-else>{{$t('tooltips.comment.public')}}</template>
                  </v-tooltip>
                </template>
              </template>
              <div>
                <div class="font-weight-normal">
                  <template v-if="comment.canEdit()">
                    <strong>{{ comment.data.userentity.getFullName() }}</strong> -
                  </template>
                  {{ comment.data.createdAt }}
                </div>
                <div>
                  <v-text-field
                    v-if="comment.states.editing"
                    v-model="comment.data.content"
                    :disabled="!canPost"
                    :loading="loading || saving"
                    :error-messages="errors.content"
                    :rules="[rules.required]"
                    hide-details="auto"
                    outlined
                    flat
                    solo
                    dense
                    clearable
                    autofocus
                    @blur="form.resetValidation()"
                    @keydown.enter="() => onUpdateComment(comment)"
                    @input="errors = {}"
                  ></v-text-field>
                  <span v-else v-text="comment.data.content"></span>
                </div>
                <div v-if="comment.canEdit()" class="d-flex align-center mt-2" style="gap: 0.5rem">
                  <template v-if="comment.states.editing">
                    <v-btn :loading="comment.states.updating" :disabled="comment.states.updating" x-small outlined @click="() => onUpdateComment(comment)">
                      <span v-text="$t('btn.save')"></span>
                    </v-btn>
                    <v-btn x-small outlined color="error" @click="() => onCancelComment(comment)">
                      <span v-text="$t('btn.cancel')"></span>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn x-small outlined @click="() => onEditComment(comment)">
                      <span v-text="$t('btn.edit')"></span>
                    </v-btn>
                    <v-btn x-small outlined color="error" @click="() => onDeleteComment(comment, commentIdx)">
                      <span v-text="$t('btn.delete')"></span>
                    </v-btn>
                  </template>
                </div>
              </div>
            </v-timeline-item>
          </template>
        </v-timeline>
      </v-list>
    </v-card-text>

  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, Ref, Watch} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import CommentService from '@/services/comment.service';
import CommentModel from '@/models/comment.model';
import Identity from '@/modules/sdk/core/identity';
import UserModel from '@/modules/sdk/models/user.model';
import Rules, { IRuleSet } from '@/modules/sdk/core/rules';

const d = new Logger('views/Admin/Component/CommentComponent');

export type VForm = Vue & { validate: () => boolean, reset: () => boolean, resetValidation: () => boolean }

@Component({
  components: {}
})
export default class CommentsComponent extends Vue {
  @Ref() readonly form!: VForm;
  @Prop() readonly recordId!: number;
  @Prop({default: false}) readonly selfOnly!: boolean;
  @Prop({default: false}) readonly disabled!: boolean;
  @Prop() userType?: string;

  user = new UserModel(Identity.identity?.user);

  loading = false;
  saving = false;
  valid = true;

  content = '';
  private = true;

  commentList = [];

  errors: any = {};
  rules: IRuleSet = {};

  @Watch('userType')
  onUserTypeChanged(userType: string | undefined) {
    this.loadCommentList();
  }

  get filteredComments(): Array<CommentModel> {
    return this.commentList.filter((comment: CommentModel) => comment.canSee(this.selfOnly));
  }

  get canPost() {
    return !this.loading && !this.saving && !this.disabled;
  }

  get canRefresh() {
    return !this.loading && !this.disabled;
  }

  onEditComment(comment: CommentModel) {
    if (comment.canEdit()) {
      comment.states.editing = true;
      comment.setOriginalData();
      this.$forceUpdate();
    }
  }

  onUpdateComment(comment: CommentModel) {
    comment.states.updating = true;
    CommentService.getInstance().update(comment)
      .then((response) => {
        this.$root.$globalSnack.info({
          message: this.$i18n.t('commentsComponent.commentUpdatedSnack')
        });
        comment.setOriginalData();
        comment.states.editing = false;
        this.$forceUpdate();
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => comment.states.updating = false);

  }

  onCancelComment(comment: CommentModel) {
    comment.revertData();
    comment.states.editing = false;
    this.$forceUpdate();
  }

  onDeleteComment(comment: CommentModel, index: number) {
    this.$root.$globalModal.ask(
      this.$i18n.t('commentsComponent.deleteComment.title'),
      this.$i18n.t('commentsComponent.deleteComment.body'),
      [{
        text: this.$i18n.t('btn.delete'),
        attrs: {
          outlined: true,
        },
        events: {
          click: () => {
            this.$root.$globalModal.setLoading(true);
            CommentService.getInstance().delete({
              id: comment.data.id
            })
              .then((response) => {
                this.commentList.splice(index, 1);
                this.$root.$globalSnack.info({
                  message: this.$i18n.t('commentsComponent.deleteComment.snack')
                });
                this.$root.$globalModal.hide();
              })
              .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
              .finally(() => this.$root.$globalModal.setLoading(false));
          },
        }
      }, {
        text: this.$i18n.t('btn.cancel'),
        attrs: {
          text: true,
        },
        events: {
          click: () => {
            this.$root.$globalModal.hide();
          },
        }
      }],
      'danger',
    )
  }

  togglePrivate(comment?: CommentModel) {
    if (!(comment instanceof CommentModel)) {
      this.private = !this.private;
    } else {
      comment.loading = true;
      comment.togglePrivate();
      CommentService.getInstance().save({
        id: comment.data.id,
        private: comment.data.private
      })
        .then((response) => {
          if (!response.data.view.saved) {
            this.$root.$globalSnack.warning({
              message: 'Failed to update comment.',
              icon: 'mdi-emoticon-dead-outline'
            });
          }
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => comment.loading = false);
    }
  }

  onRefreshClick() {
    this.loadCommentList();
  }

  onPostComment(event: any) {
    event.preventDefault();
    if (this.form.validate()) {
      this.postComment();
    }
  }

  /**
   * Post New Comment
   */
  postComment(): Promise<CommentModel> {
    this.errors = {};
    this.saving = true;

    const save = {
      recordId: this.recordId,
      content: this.content,
      private: this.private,
    };
    return CommentService.getInstance().save(save)
      .then((response) => {
        if (response.data.view.saved) {
          this.form.reset()
          this.loadCommentList();
          return response.data.view.single;
        } else {
          this.$root.$globalSnack.warning({
            message: 'An unexpected error has occurred while trying to post the comment.',
            icon: 'mdi-emoticon-dead-outline'
          });
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.saving = false);
  }

  /**
   * Load all Comments
   */
  loadCommentList(): Promise<Array<CommentModel>> {
    this.loading = true;
    const order = 'id desc';
    const filters = [{
      field: 'recordId',
      value: this.recordId,
      operator: 'equals'
    }];
    return CommentService.getInstance().getAll({filters, order})
      .then((response) => this.commentList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.loading = false);
  }

  created() {
    this.loadCommentList();
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required').toString(),
    };
  }
}
</script>
