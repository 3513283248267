<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'ai-models'"
      title="Ai Models"
      :headers="headers"
      :service="service"
      index="ai-model"
      hide-presets
    >
      <!-- BASE MODEL -->
      <template v-slot:item.baseModel="{item}">
        <v-chip label>
          {{item.getBaseModelLabel()}}
        </v-chip>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import ListView from '@/modules/common/components/ListView.vue';
import AiModelService from '@/services/ai-model.service';

@Component({
  components: {
    ListView,
  }
})
export default class AiModelList extends Vue {
  service = AiModelService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'AiModelForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Base Model',
      align: 'start',
      sortable: true,
      value: 'baseModel',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Description',
      align: 'start',
      sortable: true,
      value: 'description',
      type: 'truncate',
      item: {
        type: 'truncate',
        width: '400px',
      },
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
