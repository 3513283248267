var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"align-center justify-space-between"},[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker-multiple")]),_c('span',[_vm._v("Positions")])],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"1rem"}},[_c('v-btn',{attrs:{"disabled":!_vm.markersAreDifferent || _vm.savingCountries,"text":""},on:{"click":_vm.onResetPositionClick}},[_c('span',[_vm._v("Reset")])]),_c('v-btn',{attrs:{"loading":_vm.savingCountries,"disabled":!_vm.markersAreDifferent || _vm.savingCountries,"color":"primary"},on:{"click":_vm.onSavePositionClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save-outline")]),_c('span',[_vm._v("Save")])],1),_c('v-btn',{staticClass:"ml-6",attrs:{"icon":""},on:{"click":_vm.onToggleContent}},[(!_vm.collapsed)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1)],1)]),_c('v-expand-transition',[(!_vm.collapsed)?_c('div',[_c('v-card-text',{staticClass:"pt-0"},[_c('GoogleMaps',{staticClass:"w-100",staticStyle:{"height":"30rem"},attrs:{"map-id":"glosarx","draggable":"","show-title":""},model:{value:(_vm.markers),callback:function ($$v) {_vm.markers=$$v},expression:"markers"}})],1)],1):_vm._e()])],1),_c('ListView',{directives:[{name:"test-id",rawName:"v-test-id",value:('countries'),expression:"'countries'"}],staticClass:"mt-3",attrs:{"title":"Countries","headers":_vm.headers,"service":_vm.service,"searchable":"","hide-presets":"","index":"country"},on:{"load":_vm.onItemLoad},scopedSlots:_vm._u([{key:"item.shape",fn:function({ item }){return [_c('div',{class:'marker-tag ' + item.data.shape + '-tag',style:({
          width: 'max-content',
          paddingTop: item.data.shape === 'pin' ? '0.25rem' : 0,
          paddingBottom: item.data.shape === 'pin' ? '1.25rem' : 0,
        })},[_c('div',{staticClass:"shape no-transform",style:({
            backgroundColor: item.data.color || _vm.$vuetify.theme.themes.light.error,
          })},[(item.data.shape === 'pin')?_c('div',{staticClass:"before",style:({
              borderTopColor: (item.data.color || _vm.$vuetify.theme.themes.light.error)
            })}):_vm._e(),(item.data.icon)?_c('v-icon',[_vm._v(_vm._s(item.data.icon))]):_vm._e()],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }