import Model, {Data} from '@/modules/sdk/core/model';
import ProjectModel from '@/models/project.model';
import SurveyModel from '@/models/survey.model';
import SurveyQuestionModel from '@/models/survey-question.model';

export default class SurveyGroupModel extends Model {

  constructor(
    data: Data | Model = {}
  ) {
    super(data);
    this.setOriginalData();
  }

  default() {
    return {
      surveyquestionlist: [],
    };
  }

  relatedMap () {
    return {
      projectentity: ProjectModel,
      surveyentity: SurveyModel,
      surveyquestionlist: SurveyQuestionModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveyquestionlist: true,
    };
  }

  assign(data: Model | object, specificKeys = []) {
    super.assign(data, specificKeys);
    return this.sortByProperty('surveyquestionlist', 'position');
  }
}
