<template>
  <ModalDialog
    v-model="visible"
    :title="$t('reviewView.statusReasonModal.title')"
    max-width="400"
    scrollable
  >
    <template #body>
      <span v-text="$t('reviewView.statusReasonModal.body')"></span>

      <component
        v-model="reason"
        :is="isLeader ? 'v-combobox' : 'v-autocomplete'"
        :items="reasonList"
        :label="$t('reviewView.statusReasonModal.status')"
        item-text="data.label"
        class="mt-4"
        outlined
        dense
      />
    </template>
    <template #buttons>
      <v-btn
        color="primary"
        :disabled="!canConfirmStatus"
        :loading="loading"
        @click="onConfirmStatusReason"
      >
        <span v-text="$t('btn.confirm')"></span>
      </v-btn>
      <v-btn
        outlined
        @click="onCancel"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import RecordModel from '@/models/record.model';
import ProjectStatusReasonModel from '@/models/project-status-reason.model';
import ProjectStatusReasonService from '@/services/project-status-reason.service';
import {Component, Vue, Prop, VModel, Emit} from 'vue-property-decorator';
import Identity from "@/modules/sdk/core/identity";

@Component({
  components: {
    ModalDialog,
  }
})
export default class StatusReasonModal extends Vue {

  @VModel({default: () => false}) visible!: boolean;
  @Prop({default: () => false}) loading!: boolean;
  @Prop({default: () => new RecordModel()}) record!: RecordModel;
  @Prop({default: () => null}) stage!: string;
  @Prop({default: () => null}) status!: string;

  reasonList: Array<ProjectStatusReasonModel> = [];

  reason: string | null = null;

  get canConfirmStatus() {
    return !!this.reason && !this.loading;
  }

  get isLeader() {
    return this.record.data.projectentity?.hasRole(['leader'])
     || Identity.hasRole(['admin', 'dev']);
  }

  onConfirmStatusReason() {
    if (this.status) {
      this.$emit('reason', this.reason)
    }
  }

  onCancel() {
    this.cancel();
    this.close();
  }

  load(): Promise<any> {
    const filters = [
      [
        {
          field: 'projectId',
          operator: 'equals',
          value: this.record.data.projectId,
        },
        // OR
        {
          field: 'projectId',
          operator: 'is null',
        }
      ],
      // AND
      {
        field: 'stage',
        operator: 'equals',
        value: this.stage,
      },
      // AND
      {
        field: 'status',
        operator: 'equals',
        value: this.status,
      }
    ];

    return ProjectStatusReasonService.getInstance().getAll({filters})
      .then(response => {
        if (response.data.view.list) {
          this.reasonList = response.data.view.list;
        }
        return response;
      })
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  open() {
    this.visible = true;
  }

  close() {
    this.visible = false;
  }

  @Emit()
  cancel() {

  }
}
</script>
