<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :color="color"
    :dark="dark"
    :disabled="loading"
    max-width="450"
  >
    <template #body>
      <div
        v-test-id="'dialog-body'"
        v-html="body"
      ></div>
    </template>
    <template #buttons>
      <v-btn
        v-test-id="'dialog-confirm-button'"
        :color="dark ? null : 'primary'"
        :loading="loading"
        :disabled="loading"
        @click="onActionClick"
      >
        <span v-text="actionText"></span>
      </v-btn>
      <v-btn
        v-test-id="'dialog-cancel-button'"
        :outlined="!dark"
        :text="dark"
        :disabled="loading"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';

@Component({
  components: {
    ModalDialog,
  }
})
export default class ShouldTakeAction extends Vue {

  visible = false
  loading = false
  title: string | TranslateResult | null = null
  body: string | TranslateResult | null = null
  actionText: string | TranslateResult | null = null
  color: string | null = null
  dark = false
  onAccept: () => Promise<any> | void = () => {}

  @Watch('visible')
  onVisibleChange(visible: false) {
    if (!visible) {
      this.hide();
    }
  }

  ask(
    props: {
      title?: string | TranslateResult | null,
      body?: string | TranslateResult | null,
      actionText?: string | TranslateResult | null,
      color?: string | null,
      dark?: boolean | null,
      onAccept?: () => Promise<any> | void,
    } = {
      title: null,
      body: null,
      actionText: null,
      color: null,
      dark: false,
      onAccept: () => new Promise(resolve => resolve(true))
    }
  ) {
    this.title = props.title || this.$i18n.t('shouldTakeAction.default.title');
    this.body = props.body || this.$i18n.t('shouldTakeAction.default.body');
    this.actionText = props.actionText || this.$i18n.t('btn.confirm');
    this.color = props.color || null;
    this.dark = props.dark || false;
    this.onAccept = props.onAccept || (() => {
      this.hide();
    });
    this.show();
  }

  askDelete(
    props: {
      onAccept?: () => Promise<any> | void,
    } = {
      onAccept: () => new Promise(resolve => resolve(true))
    }
  ) {
    return this.ask({
      title: this.$i18n.t('shouldTakeAction.delete.title'),
      body: this.$i18n.t('shouldTakeAction.delete.body'),
      actionText: this.$i18n.t('btn.delete'),
      color: 'error',
      dark: true,
      onAccept: props.onAccept,
    });
  }

  askRestore(
    props: {
      onAccept?: () => Promise<any> | void,
    } = {
      onAccept: () => new Promise(resolve => resolve(true))
    }
  ) {
    return this.ask({
      title: this.$i18n.t('shouldTakeAction.restore.title'),
      body: this.$i18n.t('shouldTakeAction.restore.body'),
      actionText: this.$i18n.t('btn.restore'),
      color: 'success',
      dark: true,
      onAccept: props.onAccept,
    });
  }

  onActionClick(): void {
    const accept = this.onAccept();
    if (accept instanceof Promise) {
      this.loading = true;
      accept
        .then(() => this.hide())
        .finally(() => this.loading = false);
    } else {
      this.hide();
    }
  }

  onCancelClick(): void {
    this.hide();
  }

  show(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }

  created() {
    this.$root.$shouldTakeAction = this;
  }
}
</script>
