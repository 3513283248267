<template>
  <v-card>
    <slot name="header">
      <v-card-title>
        <v-icon left v-if="icon">{{icon}}</v-icon>
        <slot name="title"></slot>
        <v-spacer />

        <!-- OPTIONS FIELD -->
        <slot name="options"></slot>

        <v-btn
          class="ml-6"
          icon
          @click="onToggleContent"
        >
          <v-icon v-if="!computedCollapsed">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card-title>
    </slot>
    <v-expand-transition>
      <div v-if="!computedCollapsed">
        <slot name="body"></slot>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

@Component
export default class CollapsableCard extends Vue {

  @VModel({ type: Boolean, default: null }) collapsed!: boolean
  @Prop({ type: String, default: null }) icon!: string

  innerCollapse = false

  get computedCollapsed(): boolean {
    return this.collapsed === null
      ? this.innerCollapse
      : this.collapsed;
  }

  onToggleContent(): void {
    if (this.collapsed !== null) {
      this.collapsed = !this.collapsed;
    } else {
      this.innerCollapse = !this.innerCollapse;
    }
  }
}
</script>
