<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'notification'"
      title="Notifications"
      :headers="headers"
      :service="service"
      searchable
      index="notification"
    >
      <!-- EVERYONE -->
      <template v-slot:item.everyone="{item}">
        <span class="text-no-wrap">
          <v-chip
            v-if="item.data.everyone"
            color="info"
            label
            small
          >
            Everyone
          </v-chip>
          <v-chip
            v-else
            color="default"
            label
            small
          >
            Selected user
          </v-chip>
        </span>
      </template>

      <!-- TYPE -->
      <template #item.type="{item}">
        <v-chip
          :color="item.data.type | color('type')"
          class="ma-2"
          label
          small
        >
          {{ $t('type.' + item.data.type) }}
        </v-chip>
      </template>

      <!-- IMPORTANT -->
      <template v-slot:item.important="{item}">
        <span class="text-no-wrap">
          <v-chip
            v-if="item.data.important"
            color="error"
            label
            small
          >
            Yes
          </v-chip>
          <v-chip
            v-else
            color="default"
            label
            small
          >
            No
          </v-chip>
        </span>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import NotificationService from '@/modules/sdk/services/notification.service';

@Component({
  components: {
    ListView,
    DataTable,
  }
})
export default class NotificationList extends Vue {
  @Ref() readonly datatable!: DataTable

  name = 'NotificationList';
  service = NotificationService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'NotificationForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Description',
      align: 'start',
      sortable: true,
      width: 300,
      value: 'description',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Type',
      align: 'start',
      sortable: true,
      value: 'type',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Info', value: 'info' },
          { text: 'Notice', value: 'notice' },
          { text: 'Warning', value: 'warning' },
          { text: 'Error', value: 'error' },
        ]
      }
    },
    {
      text: 'Notification Target',
      align: 'start',
      sortable: true,
      value: 'everyone',
      filterable: {
        type: 'boolean',
        labelTrue: 'Everyone',
        labelFalse: 'Selected User',
      },
    },
    {
      text: 'Important',
      align: 'start',
      sortable: true,
      value: 'important',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
