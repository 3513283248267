<template>
  <v-dialog
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    v-test-id="'modal-dialog'"
    :persistent="_persistent"
    attach=".v-application"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs"></slot>
    </template>

    <v-card v-draggable="draggableOptions" :color="color">
      <div v-if="!hideHeader" :class="{ 'elevation-4': shadow }" style="z-index: 5">
        <v-card-title ref="title" :class="[
          'd-flex align-center flex-nowrap justify-space-between'
          ]" style="gap: 1rem">
          <slot name="title">
            <div class="d-flex align-center overflow-hidden w-100">
              <v-icon v-if="icon" left v-text="icon"></v-icon>
              <span class="headline" style="word-break: break-word" v-text="title"></span>
            </div>
          </slot>
          <slot name="close.prepend">
          </slot>
          <v-btn
            v-if="!hideCloseButton"
            v-test-id="'dialog-close-button'"
            :disabled="loading || disabled"
            @click="closeModal"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <slot name="header"></slot>
      </div>
      <slot name="content">
        <v-card-text ref="body" :class="backgroundColor" v-scroll.self="onScroll">
          <slot name="body">
            <div v-html="body"></div>
          </slot>
        </v-card-text>
      </slot>
      <slot name="footer"></slot>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="d-flex flex-column flex-md-row w-100 justify-end" style="gap: 0.5rem; flex: 0">
          <slot name="prepend_actions"></slot>
        </div>
        <slot name="actions">
          <div class="d-flex flex-column flex-md-row w-100 justify-end align-center" style="gap: 0.5rem">
            <slot name="buttons">
              <v-btn
                v-for="(btn, btnIdx) in computedButtons"
                v-bind="btn.attrs"
                v-on="btn.events"
                :key="btnIdx"
                :block="$vuetify.breakpoint.smAndDown"
                :disabled="typeof btn.attrs.disabled === 'function' ? btn.attrs.disabled() : btn.attrs.disabled"
                large
              >
                <span v-text="btn.text"></span>
              </v-btn>
            </slot>
          </div>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Component, Emit, ModelSync, Prop, Vue, Watch} from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

@Component({})
export default class ModalDialog extends Vue {

  @ModelSync('vModel', 'change', {
    default: false,
  }) model!: boolean

  @Prop({ type: Boolean }) loading!: boolean;
  @Prop({ type: Boolean }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) hideHeader!: boolean;
  @Prop({ type: Boolean, default: false }) hideCloseButton!: boolean;
  @Prop({ type: Boolean, default: false }) persistent!: boolean;
  @Prop({ type: Boolean, default: false }) draggable!: boolean;
  @Prop({ type: Boolean, default: false }) defaultButtons!: boolean;
  @Prop() icon!: string;
  @Prop() title!: string | TranslateResult;
  @Prop() body!: string;
  @Prop({ default: () => ([])}) buttons!: Array<any>;
  @Prop() color!: string;
  @Prop() backgroundColor!: string;

  innerButtons: Array<any> = []
  shadow = false
  draggableOptions: object|boolean = false

  onScroll(args: any) {
    const bodyRef = this.$refs.body as HTMLDivElement;
    this.shadow = args.target.scrollTop > 0;
    if (bodyRef && Math.round(args.target.scrollTop + bodyRef.clientHeight) >= Math.floor(bodyRef.scrollHeight - 100)) {
      this.$emit('scroll-to-bottom', args.target.scrollTop);
    }
  }

  handleNoScroll() {
    // scenario where we don't have any scroll
    setTimeout(() => {
      const bodyRef = this.$refs.body as HTMLDivElement;
      if (bodyRef && bodyRef.scrollHeight === bodyRef.clientHeight) {
        this.$emit('scroll-to-bottom', bodyRef.clientHeight);
      }
    }, 0);
  }

  @Watch('model')
  onModelChanged(model: boolean) {
    if (!model) {
      this.draggableOptions = false;
      this.$emit('close');
    }
    this.adjustDraggable();

    if (model) {
      this.handleNoScroll();
    }
  }

  @Emit()
  closeModal() {
    this.model = false;
  }

  @Emit()
  openModal() {
    this.model = true;
    this.adjustDraggable();
  }

  get _persistent(): boolean {
    return this.draggable ? false : this.persistent;
  }

  get computedButtons(): Array<any> {
    return this.buttons.concat(this.innerButtons).map(button => {
      return Object.assign({ attrs: {}, events: {} }, button);
    });
  }

  adjustDraggable(): void {
    if (this.model) {
      setTimeout(() => {
        const refTitle = this.$refs.title as any;
        this.draggableOptions = this.draggable && refTitle ? {
          handle: refTitle,
          container: refTitle.closest('.v-dialog'),
        } : false;
      }, 300)
    } else {
      this.draggableOptions = false;
    }
  }

  created() {
    if (this.defaultButtons) {
      this.innerButtons.push({
        attrs: {
          outlined: true,
        },
        events: {
          click: () => {
            this.closeModal();
          }
        },
        text: 'Close',
      })
    }
    this.adjustDraggable();
  }

  mounted() {
    window.addEventListener('resize', this.handleNoScroll);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleNoScroll);
  }
}
</script>
