<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    v-fixed-columns
    :headers="headers"
    :options.sync="innerOptions"
    :server-items-length="totalCount"
    :items="items"
    :items-per-page="itemsPerPage"
    :loading="loading"
    :single-select="singleSelect"
    ref="datatable"
    item-key="data.id"
    fixed-header
    multi-sort
    calculate-widths
    @update:options="onUpdateOptions"
  >

    <!-- V-SLOT -->
    <template v-for="header in Headers" #[header]="props">
      <slot :name="header" v-bind="props">
        <span :key="header">{{ props.header.text }}</span>
        <template v-if="props.header.filterable">
            <DataTableColumnFilter
              v-model="_options.filters"
              :header="props.header"
            >
            </DataTableColumnFilter>
        </template>
      </slot>
    </template>

    <!--    <template #item.data-table-select="props">-->
    <!--      // call native template #item.data-table-select-->
    <!--      <v-btn class="mx-2" small depressed fab text :to="{name: 'UserForm', params: {id: props.item.data.id}}">-->
    <!--        <v-icon dark>-->
    <!--          mdi-pencil-outline-->
    <!--        </v-icon>-->
    <!--      </v-btn>-->
    <!--    </template>-->

    <!-- V-SLOT -->
    <template v-for="(item, itemKey) in Items" #[item]="props">

      <slot v-if="props.header.item">
        <slot v-if="!props.header.item.type" :name="item" v-bind="props">
          <component
            :is="props.header.item.attrs.is || 'span'"
            v-bind="props.header.item.attrs"
            v-bind:key="itemKey"
          >
            {{ props.item.data[props.header.value] }}
          </component>
        </slot>

        <!-- BUTTON -->
        <slot v-else-if="props.header.item.type === 'button'" :name="item" v-bind="props">
          <div :key="item" class="d-flex align-center" style="gap: 0.5rem">
            <v-icon v-if="sortable" class="handle">mdi-drag-vertical</v-icon>
            <v-btn
              small
              depressed
              fab
              text
              v-bind="props.header.item.attrs"
              :to="{name: props.header.item.to.name, params: parseParams(props, props.header.item.to.params)}"
            >
              <v-icon dark v-text="props.header.item.icon.text || 'mdi-pencil-outline'" v-bind="props.header.item.icon.attrs"></v-icon>
            </v-btn>
          </div>
        </slot>

        <!-- TRUNCATE -->
        <slot v-else-if="props.header.item.type === 'truncate'" :name="item" v-bind="props">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" :style="{maxWidth: props.header.item.width}">
              {{ props.item.data[props.header.value] }}
            </span>
            </template>
            <span style="word-break: break-all;">
            {{ props.item.data[props.header.value] }}
          </span>
          </v-tooltip>
        </slot>

        <!-- DATE -->
        <slot v-else-if="props.header.item.type === 'date'" :name="item" v-bind="props">
          <div class="text-no-wrap" v-bind:key="itemKey">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ props.item.data[props.header.value] || getDateByFormat(props.header.item.format) }}
                </span>
              </template>
              <span>{{ props.item.data[props.header.value] }}</span>
            </v-tooltip>
          </div>
        </slot>

      </slot>

      <!-- LAST COL -->
      <slot v-else-if="item === 'item._last'" :name="item" v-bind="props">
        <v-btn class="mx-2" small depressed fab text color="red" @click="onDeleteClick(props.item)" :loading="props.item.Loading" v-if="!props.item.data.deleted">
          <v-icon dark>
            mdi-delete-outline
          </v-icon>
        </v-btn>

        <v-btn class="mx-2" small depressed fab text color="green" @click="onRestoreClick(props.item)" :loading="props.item.Loading" v-if="props.item.data.deleted">
          <v-icon dark>
            mdi-cached
          </v-icon>
        </v-btn>
      </slot>

      <!-- STATUS -->
      <slot v-else-if="item === 'item.status'" :name="item" v-bind="props">
        <v-chip class="ma-2" label :color="props.item.data.status || $vuetify.theme.themes.light.success" small>
          {{ $t('status.' + props.item.data.status) }}
        </v-chip>
      </slot>

      <!-- CREATED AT -->
      <slot v-else-if="['item.createdAt', 'item.updatedAt', 'item.deletedAt', 'item.restoredAt'].includes(item)" :name="item" v-bind="props">
        <div class="text-no-wrap" v-bind:key="itemKey">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ props.item.data[item.replace('item.', '')] || getDateByFormat('YYYY-MM-DD') }}
            </span>
            </template>
            <span>{{ props.item.data[item.replace('item.', '')] }}</span>
          </v-tooltip>
        </div>
      </slot>

      <!-- POSITION -->
      <slot v-else-if="item === 'item.position'" :name="item" v-bind="props">
        <v-chip class="ma-2" outlined pill>
          {{ props.item.data.position }}
        </v-chip>
      </slot>

      <!-- DELETED -->
      <slot v-else-if="item === 'item.deleted'" :name="item" v-bind="props">
        <v-chip class="ma-2" label :color="props.item.data.deleted ? $vuetify.theme.themes.light.error : $vuetify.theme.themes.light.success" dark small>
          {{ $t('deleted.' + props.item.data.deleted) }}
        </v-chip>
      </slot>

      <slot v-else :name="item" v-bind="props">
        {{ props.item.data[props.header.value] }}
      </slot>

    </template>

    <!-- SEARCH -->
    <template #top>
      <!--      <v-text-field-->
      <!--          v-model="search"-->
      <!--          append-icon="mdi-magnify"-->
      <!--          label="Search"-->
      <!--          single-line-->
      <!--      ></v-text-field>-->
    </template>

  </v-data-table>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch, PropSync, Emit, Ref} from 'vue-property-decorator';
import DataTableColumnFilter from '@/modules/common/components/DataTableColumnFilter.vue';
import Service from '@/modules/sdk/core/service';
import Model from '@/modules/sdk/core/model';
import Logger from '@/modules/sdk/core/logger';
import { debounce } from 'debounce';
import moment from 'moment';
import Sortable from 'sortablejs';
import Hash from '@/modules/sdk/core/hash';

const d = new Logger('components/DataTable');

@Component({
  components: {
    DataTableColumnFilter
  }
})
export default class DataTable extends Vue {

  @Prop({ default: 0 }) forceLoad!: number;
  @Prop({ default: '' }) search!: string;
  @Prop({ default: () => [] }) headers!: [];
  @Prop({ default: () => [] }) query!: [];
  @Prop({ default: null }) service!: Service;
  @Prop({ type: Boolean, default: false }) sortable!: boolean;
  @Prop({ type: String, default: 'position' }) sortableField!: string;
  @Prop({ default: 25 }) itemsPerPage!: number;
  @PropSync('options', { default: () => ({}) }) _options!: any;

  @Ref() datatable!: any;

  loaded = false;
  loading = true;
  totalCount = 0;
  singleSelect = false;
  items: Array<Model> = [];
  innerOptions = {}

  get Items () {
    return this.headers.map((header: any) => 'item.' + header.value);
  }

  get Headers () {
    return this.headers.map((header: any) => 'header.' + header.value);
  }

  parseParams (props: any, params: any) {
    const ret: { [key: string]: any } = {};
    for (const key in params) {
      if (params[key]) {
        // ret[key] = this.ObjectByString(props, params[key]);
        // eslint-disable-next-line no-eval
        ret[key] = eval(params[key]);
      }
    }
    return ret;
  }

  onUpdateOptions(options: any) {
    if (this.loaded) {
      this.load(Object.assign({}, this._options, options));
    }
  }

  onDeleteClick(item: any) {
    this.$root.$shouldTakeAction.askDelete({
      onAccept: () => {
        return this.deleteRecord(item);
      }
    });
  }

  onRestoreClick(item: any) {
    this.$root.$shouldTakeAction.askRestore({
      onAccept: () => {
        return this.restoreRecord(item);
      }
    })
  }

  @Emit()
  load (options = this._options, search = this.search, query = this.query) {
    if (this.service) {
      this.loadDebounce.clear();
      return this.service.getAll(this.prepareData(options, search, query))
        .then(response => {
          this.totalCount = response.data.view.totalCount;
          this.items = response.data.view.list;
          this.loaded = true
          return this.items;
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.loading = false);
    } else {
      this.loading = false
    }
  }

  getDateByFormat(format: string) {
    return moment().format(format);
  }

  bindSorting() {
    const tbody = this.datatable.$el.querySelector('tbody');
    if (tbody) {
      Sortable.create(tbody, {
        group: Hash.guid(),
        handle: '.handle',
        onEnd: (event: any) => {

          // Fetch min position
          const minPosition = this.items.reduce((minObj, currentObj) => {
            return (currentObj.data[this.sortableField] < minObj.data[this.sortableField]) ? currentObj : minObj;
          }).data[this.sortableField];

          // Update items position
          const [item] = this.items.splice(event.oldIndex, 1);
          this.items.splice(event.newIndex, 0, item);
          this.items.forEach((item, itemIdx) => {
            item.data[this.sortableField] = itemIdx + minPosition;
          });
          this.service.reorder({ id: item.data.id, position: event.newIndex + minPosition });

          // Emit event to parent
          this.$emit('sort', this.items);
        }
      });
    }
  }

  deleteRecord (item: Model) {
    item.Loading = true;
    this.service.delete({ id: item.data.id })
      .then((response: any) => {
          item.assign(response.data.view.single);
      })
      .catch((reason) => this.$root.$zemit.handleError(reason))
      .finally(() => item.Loading = false);
  }

  restoreRecord (item: Model) {
    item.Loading = true;
    this.service.restore({ id: item.data.id })
      .then((response: any) => {
          item.assign(response.data.view.single);
      })
      .catch((reason) => this.$root.$zemit.handleError(reason))
      .finally(() => item.Loading = false);
  }

  headerHasId(): boolean {
    return this.headers && Array.isArray(this.headers) && !!this.headers.findIndex((header: any) => header.value === 'id');
  }

  prepareData (options: any, search: string | null, query: any) {
    this.loading = true;

    const data: any = {};

    // Pagination
    data.limit = options.itemsPerPage;
    data.offset = (options.page - 1) * options.itemsPerPage;

    // Order
    const order: any = [];
    for (const key in options.sortBy) {
      order.push(options.sortBy[key] + (options.sortDesc[key] ? ' DESC' : ' ASC'));
    }
    if (this.headerHasId()) {
      order.push('id desc');
    }
    data.order = order.join(', ');

    // Search
    data.search = search;

    // Filter
    data.filters = query.concat(options.filters.filter((filter: any) => {
      if (filter.value !== null && !Array.isArray(filter.value)) {
        return true;
      } else if (Array.isArray(filter.value) && filter.value.length > 0 && !filter.value.includes(null)) {
        return true;
      } else if (['is null', 'is not null'].includes(filter.operator)) {
        return true;
      }
      return false;
    }));

    data.filters = data.filters.map((filter: any) => {
      const result = Object.assign({}, filter);
      if (filter.type === 'date' && filter.value.length === 1) {
        result.value = [filter.value[0], moment(filter.value[0]).add(1, 'day').format('YYYY-MM-DD')];
      } else if (filter.type === 'between' && filter.value[0] === null) {
        filter.value[0] = 0;
      } else if (filter.type === 'between' && filter.value[1] === null) {
        filter.value[1] = 0;
      }
      return result;
    })

    return data;
  }

  loadDebounce = debounce(() => {
    this.load();
  }, 333);

  @Watch('options', {
    deep: true,
  })
  onOptionsChanged (before: any, after: any) {
    if (this.loaded) {
      this.load();
    }
  }

  @Watch('forceLoad')
  onTickChanged () {
    this.load();
  }

  @Watch('search')
  onSearchChanged () {
    this.loadDebounce();
  }

  mounted() {
    if (this.sortable) {
      this.bindSorting();
    }
  }

  created() {
    this.load();
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table ::v-deep .v-data-table-header tr th {
    white-space: nowrap;
  }
  .handle {
    cursor: move;
  }
</style>
