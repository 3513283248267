<template>
  <draggable
    :value="conditionList"
    :animation="200"
    handle=".sortable-handle"
    ghost-class="background-white"
    group="builder"
    @start="drag = true"
    @end="drag = false"
    @input="onDragInput"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : undefined">
      <template>
        <v-row
          v-for="(condition, conditionIdx) of _conditionList"
          :key="conditionIdx"
        >
          <v-col style="flex: 1">
            <v-row>
              <v-col style="flex: 0" class="pt-5">
                <v-icon :disabled="conditionList.length < 2 && depth === 0" class="sortable-handle">
                  mdi-drag
                </v-icon>
              </v-col>
              <v-col style="flex: 0; min-width: 10rem">
                <v-autocomplete
                  v-model="condition.logic"
                  :items="logicList"
                  :disabled="conditionIdx === 0"
                  :style="{
                    opacity: conditionIdx === 0 ? 0.5 : 1,
                  }"
                  background-color="backgroundVeryLight"
                  placeholder="Condition"
                  hide-details="auto"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col style="flex: 1">
                <v-alert
                  v-if="condition.group.length > 0"
                  class="mb-0"
                  :style="{
                    marginRight: depth > 0 ? '-17px' : null,
                  }"
                  color="#bbb"
                  border="left"
                  outlined
                >
                  <QueryGroupBuilderChild
                    :value="conditionList[conditionIdx].group"
                    v-bind="$attrs"
                    v-on="$listeners"
                    :fields="fields"
                    :depth="depth + 1"
                    :parent-item-index="conditionIdx"
                    :parent-list="conditionList"
                    :parent-instance="instance"
                    @ungroup="onUngroup"
                    @removeEmpty="onRemoveEmpty"
                  />
                </v-alert>
                <v-row v-else>
                  <v-col cols="12" md="4" lg="4" xl="4">

                    <!-- FIELD -->
                    <v-autocomplete
                      v-model="condition.field"
                      :items="fields"
                      :return-object="returnObject"
                      placeholder="Field"
                      hide-details="auto"
                      height="40"
                      clearable
                      outlined
                      dense
                      @change="field => onFieldChange(condition, field)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" lg="4" xl="2">

                    <!-- COMPARATOR -->
                    <v-autocomplete
                      v-model="condition.operator"
                      :items="getOperatorList(condition)"
                      hide-details="auto"
                      placeholder="Comparator"
                      height="40"
                      outlined
                      dense
                      class="text-no-wrap"
                      @change="changeOperator(condition)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5" lg="4" xl="6">

                    <!-- VALUES -->
                    <DialogDateField
                      v-if="isFieldType(['date'], condition)"
                      v-model="condition.value"
                      :input-attrs="{
                        hideDetails: 'auto',
                        label: $t('placeholder.chooseDate'),
                      }"
                      @input="() => $forceUpdate()"
                    />
                    <DialogDateTimeField
                      v-else-if="isFieldType(['date_time'], condition)"
                      v-model="condition.value"
                      :label="$t('placeholder.chooseDateTime')"
                      @input="() => $forceUpdate()"
                    />
                    <DialogTimeField
                      v-else-if="isFieldType(['time'], condition)"
                      v-model="condition.value"
                      :input-attrs="{
                        hideDetails: 'auto',
                        label: $t('label.pickTime'),
                        outlined: true,
                        dense: true,
                      }"
                      @input="() => $forceUpdate()"
                    />
                    <component
                      v-else-if="isFieldType(['list', 'select'], condition)"
                      v-model="condition.value"
                      v-bind="getValueAttrs(condition)"
                      :is="isFieldType('list', condition) ? 'v-combobox' : 'v-autocomplete'"
                      :disabled="isDisabledValue(condition)"
                      :return-object="returnObject"
                      hide-details="auto"
                      multiple
                      outlined
                      dense
                      clearable
                      chips
                      hide-selected
                    >
                      <template #selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          :color="item.color"
                          :dark="item.dark"
                          chips
                          clearable
                        >
                        <span class="mr-2">
                          {{ typeof item === 'object' ? item.text : item }}
                        </span>
                          <v-btn icon x-small @click="parent.selectItem(item)">
                            <v-icon small>
                              $delete
                            </v-icon>
                          </v-btn>
                        </v-chip>
                      </template>
                      <template #item="{ attrs, item, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          :color="item.color"
                          :dark="item.dark"
                          chips
                          clearable
                        >
                          {{ typeof item === 'object' ? item.text : item }}
                        </v-chip>
                      </template>
                    </component>
                    <v-text-field
                      v-else-if="isFieldType('numeric', condition)"
                      v-model.number="condition.value"
                      v-bind="getValueAttrs(condition)"
                      :disabled="isDisabledValue(condition)"
                      type="number"
                      hide-details="auto"
                      outlined
                      dense
                      clearable
                    />
                    <v-text-field
                      v-else
                      v-model="condition.value"
                      v-bind="getValueAttrs(condition)"
                      :disabled="isDisabledValue(condition)"
                      hide-details="auto"
                      outlined
                      dense
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="condition.group.length === 0" class="d-flex flex-nowrap justify-end" style="flex: 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      fab
                      text
                      small
                      :disabled="depth === 0"
                      @click="ungroupCondition(conditionIdx, conditionList, parentItemIndex)"
                    >
                      <v-icon dark>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('btn.ungroup')"></span>
                </v-tooltip>

                <v-btn
                  fab
                  text
                  small
                  color="red"
                  :disabled="isDefaultState"
                  @click="deleteCondition(conditionIdx, conditionList, parentItemIndex)"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="green"
                  @click="addCondition(conditionIdx + 1, conditionList)"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>

                <v-tooltip v-if="maxDepth === -1 || maxDepth < depth" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      text
                      small
                      @click="groupCondition(conditionIdx, conditionList)"
                    >
                      <v-icon dark>
                        mdi-arrow-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="$t('btn.group')"></span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </transition-group>
  </draggable>
</template>

<script lang="ts">
import 'reflect-metadata';
import draggable from 'vuedraggable';
import { defaultQueryBuilderItem } from '@/modules/common/components/QueryBuilder.vue'
import DialogDateField from '@/modules/common/components/DialogDateField.vue'
import DialogDateTimeField from '@/modules/common/components/DialogDateTimeField.vue'
import DialogTimeField from '@/modules/common/components/DialogTimeField.vue'
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { logicList, operatorList } from '@/enums/global';
import Identity from '@/modules/sdk/core/identity';

@Component({
  components: {
    draggable,
    DialogDateField,
    DialogDateTimeField,
    DialogTimeField,
    'QueryGroupBuilderChild': () => import('@/modules/common/components/QueryGroupBuilder.vue')
  }
})
export default class QueryGroupBuilder extends Vue {

  @VModel({ type: Array, default: () => [] }) conditionList!: any[];
  @Prop({ type: Number, default: 0 }) readonly parentItemIndex!: number;
  @Prop({ type: Number, default: 0 }) readonly depth!: number;
  @Prop({ type: Boolean, default: false }) readonly isDefaultState!: boolean;
  @Prop({ type: Boolean, default: true }) readonly returnObject!: boolean;
  @Prop({ type: Number, default: -1 }) readonly maxDepth!: number;
  @Prop({ type: Vue }) parentInstance!: QueryGroupBuilder;
  @Prop({ default: () => ([]) }) readonly fields!: Array<{
    header?: string,
    text?: string,
    value?: string,
  }>;

  instance: QueryGroupBuilder | undefined
  drag = false
  logicList = logicList

  get operatorList(): any[] {
    return operatorList.filter(item => {
      return !['regexp', 'not regexp'].includes(item.value) || !Identity.hasRole(['client']);
    })
  }

  get _conditionList() {
    return this.conditionList;
  }

  set _conditionList(list: any[]) {
    this.$emit('input', list);
  }

  getOperatorList(condition: any): Array<any> {
    const field: any = this.fields.find(field => field.value === condition.field) || {};
    if (Array.isArray(field.operators)) {
      return this.operatorList.filter(operator => field.operators.includes(operator.value));
    }
    return this.operatorList;
  }

  onFieldChange(condition: any, field: any) {
    condition.value = null;

    let items = [];
    if (field && field.type === 'list') {
      items = field.items;

      if (items.includes('Yes')) {
        condition.value = ['Yes']
      }
    }

    if (field) {
      condition.field = field.value;
      condition.category = field.category;
    }
  }

  onUngroup(props: {
    index: number,
    item: any,
    childIdx: number,
  }) {
    if (!this.conditionList[props.index] || !this.conditionList[props.index].group || this.conditionList[props.index].length === 0) {
      return;
    }

    // bring element to parent
    this.conditionList[props.index].group.splice(props.childIdx, 1);

    // remove element from group
    this.conditionList.splice(props.index, 0, structuredClone(props.item));

    // delete old group if empty
    if (this.conditionList[props.index + 1].group.length === 0) {
      this.deleteCondition(props.index + 1, this.conditionList, this.parentItemIndex);
    }

    this.$forceUpdate();
  }

  onRemoveEmpty(props: {
    index: number,
  }) {
    if (!this.conditionList[props.index] || !this.conditionList[props.index].group || this.conditionList[props.index].length === 0) {
      return;
    }

    if (this.conditionList[props.index].group.length === 0) {
      this.deleteCondition(props.index, this.conditionList, this.parentItemIndex);
    }
    this.$forceUpdate();
  }

  groupCondition(conditionIdx: number, conditionList = this.conditionList) {
    const clone = structuredClone(conditionList[conditionIdx]);
    clone.group = [];
    Object.assign(conditionList[conditionIdx], {
      field: null,
      value: null,
      operator: null,
    })
    conditionList[conditionIdx].group.push(clone);
  }

  ungroupCondition(
    conditionIdx = 0,
    conditionList = this.conditionList,
    parentIndex = this.parentItemIndex,
  ) {
    this.parentInstance.onUngroup({
      index: parentIndex,
      item: conditionList[conditionIdx],
      childIdx: conditionIdx,
    })
  }

  changeOperator(condition: any) {
    if ([
      'is empty',
      'is not empty'
    ].indexOf(condition.operator) !== -1) {
      condition.value = '';
    }
  }

  isDisabledValue(condition: any) {
    return [
      'is empty',
      'is not empty'
    ].indexOf(condition.operator) !== -1 || !condition.field;
  }

  isFieldType(type: string | Array<string>, condition: any) {
    if (['regexp', 'not regexp'].includes(condition.operator)) {
      return false;
    }

    const types = Array.isArray(type) ? type : [type];
    const field = this.getField(condition.field);
    return (field && types.includes(field.type));
  }

  getField(field: string): any {
    return this.fields.find(item => item.value === field);
  }

  getValueAttrs(condition: any): any {
    let items = [];
    const field = this.getField(condition.field);
    if (field && ['list', 'select'].includes(field.type)) {
      items = field.items;
    }
    return {
      items,
      itemColor: 'color',
    };
  }

  deleteCondition(index: number, conditionList: any[], parentIndex = this.parentItemIndex) {
    conditionList.splice(index, 1);
    if (conditionList.length === 0 && this.depth === 0) {
      this.addCondition();
    }
    if (this.parentInstance) {
      this.parentInstance.onRemoveEmpty({
        index: parentIndex,
      })
    }
  }

  addCondition(conditionIdx = 0, conditionList = this.conditionList) {
    conditionList.splice(conditionIdx, 0, this.getNewCondition());
  }

  getNewCondition(): any {
    return structuredClone(defaultQueryBuilderItem);
  }

  onDragInput(values: any[]) {
    this._conditionList.splice(0, this._conditionList.length);
    this._conditionList.push(...values.filter(value => value.operator !== null || value.group.length !== 0));
  }

  created() {
    this.instance = this;
  }
}
</script>

<style lang="scss" scoped>
.draggable {
  background-color: white !important;
  opacity: 1 !important;
  transition: transform 0.5s;
}
.sortable-handle {
  cursor: move !important;
}
.no-move {
  transition: transform 0s;
}
</style>
