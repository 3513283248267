<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'templates'"
      index="template"
      title="Templates"
      :headers="headers"
      :service="service"
      :hide-presets="true"
    >
      <!-- INDEX -->
      <template v-slot:item.index="{item}">
        <v-chip
          :to="{name: 'TemplateForm', params: {id: item.data.id}}"
          class="ma-2"
          label
          small
        >
          {{ item.data.index }}
        </v-chip>
      </template>

    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import TemplateService from '@/modules/sdk/services/template.service';

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class TemplateList extends Vue {
  service = TemplateService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'TemplateForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Index',
      align: 'start',
      sortable: true,
      value: 'index',
      filterable: true
    },
    {
      text: 'Label',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: true,
    },
    {
      text: 'Title',
      align: 'start',
      sortable: true,
      value: 'title',
      filterable: true,
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
